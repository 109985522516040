<template>
  <SsoLayout class="flex">
    <Card>
    <component
      :is="step"
      :path="'/employer/login'"
      @submit="submit($event)"
    />
    </Card>
  </SsoLayout>
</template>

<script lang="ts">
import ResetPassword from '../../components/auth/ResetPassword.vue';
import ResetPasswordSuccess from '../../components/auth/ResetPasswordSuccess.vue';
import SsoLayout from '../../layouts/SsoLayout.vue';
import Card from '../../components/Card.vue'

export default {
  name: 'EmployerResetPassword',
  components: { SsoLayout, Card },
  data () {
    return {
      current: 'password',
    }
  },
  computed: {
    step () {
      return this.current === 'password' ? ResetPassword : ResetPasswordSuccess
    }
  },
  methods: {
    async submit (e: unknown) {
      this.current = 'success'
    }
  }
}
</script>
