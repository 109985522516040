<script lang="ts" setup>
import Footer from '../components/core/Footer/FooterWebComponent.vue';
</script>

<template>
  <div class="flex flex-col h-screen justify-between">
    <div class="z-10">
      <dhi-seds-nav-header-employer id="dhi-employer-nav"></dhi-seds-nav-header-employer>
    </div>
    <main class="flex flex-row flex-1">
      <div class="bg-dice-cyan-400 md:w-1/6 md:w-1/3" />
      <div class="mx-6 xl:ml-28 pt-12 mb-32 xl:mb-64">
        <slot />
      </div>
    </main>
    <Footer />
  </div>
</template>
