<template>
  <CandidateLayout class="flex">
    <Card>
    <component
      :is="step"
      :path="'/dashboard/login'"
      @submit="submit($event)"
    />
    </Card>
  </CandidateLayout>
</template>

<script lang="ts">
import ResetPassword from '../../components/auth/ResetPassword.vue';
import ResetPasswordSuccess from '../../components/auth/ResetPasswordSuccess.vue';
import CandidateLayout from '../../layouts/CandidateLayout.vue';
import Card from '../../components/Card.vue'

export default {
  name: 'ResetPassword',
  layout: 'CandidateLayout',
  components: { CandidateLayout, Card },
  data () {
    return {
      current: 'password'
    }
  },
  computed: {
    step () {
      return this.current === 'password' ? ResetPassword : ResetPasswordSuccess
    }
  },
  methods: {

    async submit (e: unknown) {
      this.current = 'success'
    }
  }
}
</script>
