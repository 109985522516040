import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
  parseAccessToken,
  getAccessTokenStatus,
  clearTokenCookies,
  getAccessToken,
  getIdentityToken,
  getRefreshToken,
  getRememberMeFromLocalStorage,
  setTokenCookies, setTokenLocalStorage, clearTokenLocalStorage
} from '../domain/token';
import {getEnvironment, getQueryParams} from '../lib/url.lib';
// TODO: Migrate persistent values to this store.
// TODO: Paramaterize
const NEED_LOGIN_BUFFER_TIME_SECONDS = 120;

export const useTokenStore = defineStore('tokenStore', () => {
  const accessTokenEncoded = ref(getAccessToken());
  const identityTokenEncoded = ref(getIdentityToken());
  const refreshTokenEncoded = ref(getRefreshToken());
  const deviceId = ref(getRememberMeFromLocalStorage());
  const queryParams = ref(getQueryParams());

  const token = computed(() => parseAccessToken(accessTokenEncoded.value));
  const status = computed(() => getAccessTokenStatus(token.value, NEED_LOGIN_BUFFER_TIME_SECONDS));

  function set(accessToken = '', identityToken = '', refreshToken = '') {
    if (getEnvironment() === 'prod' || getEnvironment() === 'pilot') {
      setTokenCookies(accessToken, identityToken, refreshToken);
    }
    setTokenLocalStorage(identityToken, refreshToken);

    accessTokenEncoded.value = accessToken;
    identityTokenEncoded.value = identityToken;
    refreshTokenEncoded.value = refreshToken;
  }

  function clear() {
    if (getEnvironment() === 'prod' || getEnvironment() === 'pilot') {
      clearTokenCookies();
    }
    clearTokenLocalStorage();

    accessTokenEncoded.value = undefined;
    identityTokenEncoded.value = undefined;
    refreshTokenEncoded.value = undefined;
  }

  return {
    token,
    status,
    queryParams,
    deviceId,
    set,
    clear
  };
});
