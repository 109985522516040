interface QueryParams {
  [key: string]: string;
}

/**
 * Gets the domain from the url.
 * Pass in window.location.href
 * @returns {string} the domain without the protocol or subdomain
 */
export const getDomainWithoutSubdomain = (href: string) => {
  const urlParts = new URL(href).hostname.split('.');

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

/**
 * Returns correctly formatted query params.
 * @return {string} - formatted query params.
 */
export const getQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const queryParams: QueryParams = {}
  urlParams.forEach((value, key) => {
    queryParams[key] = value
  })
  const state = queryParams.state
  if (!state) return ''
  const encodedState = encodeURIComponent(state)
  // These need to be added with ? instead of & for some reason.
  // I think it has something to do with the customer app
  const otherParams = Object.keys(queryParams).map(key =>  {
    return key !== 'state' ? `?${key}=${encodeURIComponent(queryParams[key])}` : ''
  })
  return `?state=${encodedState}` + otherParams.join('')
};

export const getQueryStringParam = (keys: string[]) => {
  const urlParams = new URLSearchParams(window.location.search);
  return keys.map((k) => urlParams.get(k));
};

export const redirect = (redirectPath: string, queryParams = '') => {
  const redirectUrl = `${redirectPath}${queryParams}`;
  console.log('Redirecting to offsite:', redirectUrl)
  window.location.href = redirectUrl;
};

export const getEnvironment = () => {
  const hostname: string = window.location.hostname;
  if (hostname.includes('localhost')) {
    return 'local';
  } else if (hostname.includes('dev')) {
    return 'dev';
  } else if (hostname.includes('qa')) {
    return 'qa';
  } else if (hostname.includes('pilot')) {
    return 'pilot';
  } else {
    return 'prod';
  }
}
