<template>
  <div class="">
    <ul>
      <li v-for="requirement in requirements">
        <ValidationItem :validationText=requirement.name :pass=requirement.pass(password) :showIcon="requirement.showIcon"/>
      </li>
      <li class="pl-2"><em>Has not been used as one of your last five passwords</em></li>
    </ul>
  </div>
</template>

<script lang="ts">
import ValidationItem from '../../../components/auth/PasswordValidator/ValidationItem.vue'

export default {
  name: 'PasswordValidationList',
  components: {
    ValidationItem
  },
  props: {
    password: { required: false, type: String, default: '' },
  },
  data() {
    return {
      requirements: [
        {
          name: 'At least 8 characters long',
          pass: this.passwordLength
        },
        {
          name: 'One letter',
          pass: this.containsLetter
        },
        {
          name: 'One number',
          pass: this.containsNumber
        },
        {
          name: 'Only allowed special characters: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -',
          pass: this.allowedSpecialCharacters
        },
      ],
    }
  },
  methods: {

    /**
     *Checks a given password to see if it is at least 8 characters long
     * @param password to be checked for length
     *
     * @returns {boolean|null} true if password is at least 8 characters long, false if not, null if password is empty
     */
    passwordLength(password: string) {
      return password.length > 0 ? password.length > 7 : null
    },

    /**
     * Checks a given password to see if it contains at least one letter
     * @param password to be checked for letter
     *
     * @returns {boolean|null} true if password contains at least one letter, false if not, null if password is empty
     */
    containsLetter(password: string) {
      return password.length > 0 ? !!password.match(/[a-zA-Z]/) : null
    },

    /**
     * Checks a given password to see if it contains at least one number
     *
     * @param password to be checked for number
     * @returns {boolean|null}
     */
    containsNumber(password: string) {
      return password.length > 0 ? !!password.match(/\d/) : null
    },
    allowedSpecialCharacters(password: string) {
      const allowedCharacters = /^[a-zA-Z0-9\^\$\*\.\[\]\{\}\(\)\?\"!@#%&\/\\,><':;\|_~` =+\-]*$/
      return password.length > 0 ? allowedCharacters.test(password) : null
    }
  }
}
</script>
