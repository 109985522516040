import { z } from 'zod';
import gql from 'graphql-tag';

export const sendForgotPasswordMutation = gql`
  mutation ($email: String!, $access: String) {
    sendForgotPassword(input: { email: $email, access: $access }) {
      status
    }
  }
`;

export const SendForgotPasswordInputSchema = z.object({
  email: z.string(),
  access: z.string().optional()
});

export const SendForgotPasswordResultSchema = z.object({
  sendForgotPassword: z.object({
    status: z.string()
  })
});

export type SendForgotPasswordInput = z.infer<typeof SendForgotPasswordInputSchema>;
export type SendForgotPasswordResult = z.infer<typeof SendForgotPasswordResultSchema>;
