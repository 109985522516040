import { z } from 'zod';
import gql from 'graphql-tag';

export const respondToMfaChallengeMutation = gql`
  mutation (
    $email: String!
    $password: String!
    $code: String!
    $session: String!
    $deviceId: String
  ) {
    respondToMfaChallenge(
      input: {
        email: $email
        password: $password
        code: $code
        session: $session
        deviceId: $deviceId
      }
    ) {
      status
      access_token
      identity_token
      refresh_token
      new_session
      attempts_remaining
    }
  }
`;

export const RespondToMfaChallengeInputSchema = z.object({
  email: z.string(),
  password: z.string(),
  code: z.string(),
  session: z.string(),
  deviceId: z.string().optional()
});

export const RespondToMfaChallengeResultSchema = z.object({
  respondToMfaChallenge: z.object({
    status: z.string(),
    access_token: z.string(),
    identity_token: z.string(),
    refresh_token: z.string(),
    new_session: z.string().optional(),
    attempts_remaining: z.number()
  })
});

export type RespondToMfaChallengeInput = z.infer<typeof RespondToMfaChallengeInputSchema>;
export type RespondToMfaChallengeResult = z.infer<typeof RespondToMfaChallengeResultSchema>;
