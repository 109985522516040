<script lang="ts" setup>
import Footer from '../components/core/Footer/FooterWebComponent.vue';
</script>

<template>
  <div class="flex flex-col bg-gray-100">
    <div class="z-10">
      <dhi-seds-nav-header-employer id="dhi-employer-nav"></dhi-seds-nav-header-employer>
    </div>
    <main class="flex flex-row flex-1 self-center">
        <slot />
    </main>
    <Footer />
  </div>
</template>
