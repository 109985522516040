import { z } from 'zod';
import gql from 'graphql-tag';

export const updateForgotPasswordMutation = gql`
  mutation ($username: String!, $password: String!, $confirmationCode: String!) {
    updateForgotPassword(
      input: { username: $username, password: $password, confirmationCode: $confirmationCode }
    ) {
      status
    }
  }
`;

export const UpdateForgotPasswordInputSchema = z.object({
  username: z.string(),
  password: z.string(),
  confirmationCode: z.string()
});

export const UpdateForgotPasswordResultSchema = z.object({
  updateForgotPassword: z.object({
    status: z.string()
  })
});

export type UpdateForgotPasswordInput = z.infer<typeof UpdateForgotPasswordInputSchema>;
export type UpdateForgotPasswordResult = z.infer<typeof UpdateForgotPasswordResultSchema>;
