<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class=""
    viewBox="0 0 512 512"
    :width="width"
    :height="width"
  >
    <title>Help Circle</title>
    <path
      :fill="fill"
      :stroke="fill"
      d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64zm-6 304a20 20 0 1120-20 20 20 0 01-20 20zm33.44-102C267.23 276.88 265 286.85 265 296a14 14 0 01-28 0c0-21.91 10.08-39.33 30.82-53.26C287.1 229.8 298 221.6 298 203.57c0-12.26-7-21.57-21.49-28.46-3.41-1.62-11-3.2-20.34-3.09-11.72.15-20.82 2.95-27.83 8.59C215.12 191.25 214 202.83 214 203a14 14 0 11-28-1.35c.11-2.43 1.8-24.32 24.77-42.8 11.91-9.58 27.06-14.56 45-14.78 12.7-.15 24.63 2 32.72 5.82C312.7 161.34 326 180.43 326 203.57c0 33.83-22.61 49.02-42.56 62.43z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'QuestionCircleIcon',
  props: {
    fill: {
      type: String,
      default: '#333333',
      required: false
    },
    width: {
      type: Number,
      default: 50,
      required: false
    }
  }
};
</script>
