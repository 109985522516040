import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import getApolloClient from '../lib/apollo.client';
import {
  SendForgotPasswordInput,
  SendForgotPasswordResult,
  sendForgotPasswordMutation
} from '../domain/auth-api/mutations/send-forgot-password';

export const useForgotPasswordStore = defineStore('forgotPasswordStore', () => {
  const status = ref<string | undefined>('');
  const processing = ref(false);

  async function SEND_FORGOT_PASSWORD(email: string) {
    processing.value = true;
    const access = Cookies.get('access');

    try {
      const resp = await getApolloClient().mutate<
        SendForgotPasswordResult,
        SendForgotPasswordInput
      >({
        mutation: sendForgotPasswordMutation,
        variables: {
          email: email,
          access: access
        }
      });
      status.value = resp?.data?.sendForgotPassword?.status;
    } catch (e) {
      console.error(email, e);
      status.value = 'An error occurred while processing request, please contact support.';
    } finally {
      processing.value = false;
    }
  }

  return {
    status,
    SEND_FORGOT_PASSWORD
  };
});
