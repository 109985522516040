<template>
  <div class="flex p-1">
    <div class="w-5" :showIcon="showIcon">
      <CheckIcon :pass="pass" />
    </div>
    <div class="">
      <span class="px-1">{{ validationText }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import CheckIcon from './CheckIcon.vue';

export default {
  name: 'ValidationItem',
  components: {
    CheckIcon
  },
  props: {
    showIcon: { default: undefined, type: Boolean },
    validationText: { required: true, type: String },
    pass: { default: null, type: Boolean }
  }
};
</script>
