<script lang="ts">
import Footer from '../components/core/Footer/FooterWebComponent.vue';
export default {
  name: 'CandidateLayout'
};
</script>

<template>
  <div class="flex flex-col bg-gray-100">
    <dhi-seds-nav-header></dhi-seds-nav-header>
    <dhi-header id="dhiJobHeader"></dhi-header>
    <main class="flex flex-row flex-1 self-center">
      <slot />
    </main>
    <Footer class="z-10" />
  </div>
</template>
