import { createWebHistory, createRouter } from "vue-router";
import { nextTick } from "vue";
import Login from './employer/login.vue'
import MFAChallengeResponse from './employer/challenge-response.vue'
import ConfirmEmail from './employer/confirm-email.vue'
import ConfirmPhone from './employer/confirm-phone-number.vue'
import ForgotPassword from './employer/forgotten-password.vue'
import MFAMethod from './employer/mfa-method.vue'
import MFAOptIn from './employer/mfa-opt-in.vue'
import ResetPassword from './employer/reset-password.vue'
import AccountResetPassword from './account/reset-account-password.vue'
import SSO from './sso/index.vue'
import Page404 from './404.vue'
import TestLogin from './test/login.vue'
import TestEnterPassword from './test/enter-password.vue'
import SsoSsoSignIn from './sso/sso-sign-in.vue'
import AccountLocked from './test/account-locked.vue'
import changePasswordVue from "./employer/change-password.vue";
import SsoLoginLanding from "./sso/login-landing.vue";

const routes = [
  // {
  //   path: "/employer/login",
  //   name: "Home",
  //   component: Login,
  //   meta: {
  //       title: 'Dice.com | Employer Login'
  //   }
  // },
  {
    path: "/employer/challenge-response",
    name: "MFAChallengeResponse",
    component: MFAChallengeResponse,
    meta: {
        title: 'Dice.com | MFA Challenge Response'
    }
  },
  {
    path: '/employer/confirm-email',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: {
        title: 'Dice.com | Confirm Email'
    }
  },
  {
    path: '/employer/confirm-phone-number',
    name: 'ConfirmPhone',
    component: ConfirmPhone,
    meta: {
        title: 'Dice.com | Confirm Phone'
    }
  },
  {
    path: '/employer/forgotten-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
        title: 'Dice.com | Forgot Password'
    }
  },
  {
    path: '/employer/mfa-method',
    name: 'MFAMethod',
    component: MFAMethod,
    meta: {
        title: 'Dice.com | MFA Method'
    }
  },
  // {
  //   path: '/employer/mfa-opt-in',
  //   name: 'MFAOptIn',
  //   component: MFAOptIn,
  //   meta: {
  //       title: 'Dice.com | MFA Opt In'
  //   }
  // },
  {
    path: '/employer/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
        title: 'Dice.com | Reset Password'
    }
  },
  {
    path: '/sso',
    name: 'SSO',
    component: SSO,
    meta: {
        title: 'Dice.com | Sso'
    }
  },
  {
    path: '/account/reset-password',
    name: 'AccountResetPassword',
    component: AccountResetPassword,
    meta: {
        title: 'Dice.com | Reset Password'
    }
  },
  {
    path: '/employer/login',
    name: 'Login',
    component: TestLogin,
    meta: {
      title: 'Dice.com | Employer Login'
    }
  },
  {
    path: '/employer/enter-password',
    name: 'EnterPassword',
    component: TestEnterPassword,
    meta: {
      title: 'Dice.com | Enter Password'
    }
  },
  {
    path: '/employer/sso-sign-in',
    name: 'SsoSignIn',
    component: SsoSsoSignIn,
    meta: {
      title: 'Dice.com | SSO Login'
    }
  },
  {
    path: '/employer/account-locked',
    name: 'AccountLocked',
    component: AccountLocked,
    meta: {
      title: 'Dice.com | Account Locked'
    }
  },
  {
    path: '/employer/change-password',
    name: 'ChangePassword',
    component: changePasswordVue,
    meta: {
      title: 'Dice.com | Change Password'
    }
  },
  {
    path: '/sso/login-landing',
    name: 'SsoLoginLanding',
    component: SsoLoginLanding,
    meta: {
      title: 'Dice.com | Login Landing'
    }
  },
  // Fallback route when all else fail
  { path: '/:pathMatch(.*)*', component: Page404 }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        const title = typeof to.meta.title === 'string' ? to.meta.title : ''
        document.title = title
    });
});


export default router;


