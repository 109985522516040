<script setup lang="ts">
  import {
    getIdentityToken,
    getRefreshToken,
    setTokenLocalStorage
  } from '../../domain/token';
  import { useRoute } from 'vue-router';

  setTokenLocalStorage(getIdentityToken(), getRefreshToken());

  const baseDomain = process.env.BASE_DOMAIN;
  const route = useRoute();
  
  const url = new URL(`${baseDomain}/employer/login-landing`);
  Object.keys(route.query).forEach(key => {
    const value = route.query[key];
    url.searchParams.append(key, 
      Array.isArray(value) ? value.join(',') : value
    )
  });
  const fullUrl = url.toString();

  console.log('Redirecting to offsite:', fullUrl);
  window.location.href = fullUrl;
</script>

<template>
</template>

<style scoped>
</style>