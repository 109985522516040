<template>
  <div class="flex-col">
    <h1 class="dice-text-100 pb-4 text-center">Password Assistance</h1>
    <form class="w-full max-w-lg" autocomplete="on" ref="form" @submit.prevent="submit" >
      <div class="flex">
        <div class="w-full">
          <p class="text-dice-gray-750 w-fit pb-12 text-center">
            Enter the email address associated with your account. We will email you a link to create
            a new password.
          </p>
          <label class="dice-label mb-1" for="email">
            Email Address <span class="text-red-500">*</span>
          </label>
          <input
            id="email"
            v-model="email"
            name="email"
            type="email"
            autofocus
            autocomplete="email"
            :class="errorMessage ? 'dice-input border-red-500' : 'dice-input'"
          />
          <div id="errorText" class="h-12">
            <p v-if="errorMessage" class="dice-error-text">
              {{ errorMessage }}
            </p>
          </div>
          <div class="flex flex-col">
            <button
              id="nextBtn"
              role="button"
              class="dice-btn py-3 font-thin rounded-full"
              type="submit"
            >
              Send Link
              <span
                v-if="processing === true"
                class="spinner-border animate-spin inline-block w-4 h-4 border-1 rounded-full"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </span>
            </button>
            <button
              role="button"
              class="text-dice-blue-500 border border-dice-blue-500 rounded rounded-full px-5 py-2.5 text-center mt-2"
              type="button"
              @click="back"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import consola from 'consola';
import { useForgotPasswordStore } from '../../stores/forgotPasswordStore';

export default {
  name: 'InitiateForgotPassword',
  props: {
    onSubmit: {
      type: Function
    }
  },
  setup() {
    const forgotPasswordStore = useForgotPasswordStore()
    return { forgotPasswordStore }
  },
  data() {
    return {
      email: '',
      processing: false,
      errorMessage: ''
    };
  },
  head() {
    return {
      title: '| Forgot Password'
    };
  },
  methods: {
    back() {
      this.$router.push('/employer/login');
    },

    async submit() {
      // eslint-disable-next-line curly
      if (this.processing) return;
      if (!this.validateInput()) {
        this.errorMessage = 'You must provide information';
        return;
      }

      try {
        this.processing = true;
        this.errorMessage = '';

        await this.forgotPasswordStore.SEND_FORGOT_PASSWORD(
          this.email.trim()
        );

        if (this.forgotPasswordStore.status === 'SUCCESS') {
          this.onSubmit()
          this.$refs.form.reset()
        } else {
          this.errorMessage =
          this.forgotPasswordStore.status ??
            'An error occurred while processing forgot password request, please contact support.';
        }
      } catch (err) {
        consola.error('Error:', err);
      } finally {
        this.processing = false;
      }
    },

    validateInput() {
      const re =
        String(this.email.trim())
          .toLowerCase()
          .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || [];
      return re.length > 0;
    }
  }
};
</script>
