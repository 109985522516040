<template>
  <MFALayout class="confirm-phone-number-wrapper">
    <Card>
      <div>
        <DiceTitle title-text="Select your phone number" class="text-center" />
        <div class="pb-4">
          <p class="subtext text-dice-gray-750 text-center">
            Message and data rates may apply.
          </p>
        </div>
        <DiceRadioContainer :choices="mfaStore.phoneNumbers" @selection="phoneNumberSelection" />
        <div class="flex justify-center">
          <RememberMe class="mb-8" @checked="rememberMeAction" />
        </div>
        <div class="flex flex-col mt-10 space-y-2 pb-12">
          <DiceButton class="rounded-full"
            data-cy="sendCodeBtn"
            btn-type="primary"
            btn-text="Send Code"
            click-handler="SET_MFA_CHANNEL"
            :form-data="{ phoneNumber: mfaStore.phoneNumbers[0] , rememberMeChecked }"
            :show-loader="true"
          />
          <button class="cancel-btn text-dice-blue-500 border border-dice-blue-500 rounded px-5 py-3 text-center w-full rounded-full" @click="confirmPhoneNumberCancel">
            Cancel
          </button>
        </div>
        <hr>
        <div class="grid grid-rows-2 md:gap-10 pt-12 pb-0">
          <div>
            <p class="dice-text text-xl pb-2" data-cy="candidateLoginHeader">
              Are you having trouble sending the code?
            </p>
            <a :href="`${baseDomain}/about/contact-us`" class="dice-link pb-3 pt-3" data-cy="candidateLoginLink">Contact Support</a>
          </div>
        </div>
      </div>
    </Card>
  </MFALayout>
</template>
<script lang="ts">
import DiceRadioContainer from '../../components/core/DiceRadioBtns/index.vue'
import DiceButton from '../../components/core/diceButton/DiceButton.vue'
import DiceTitle from '../../components/core/DiceTitle/index.vue'
import RememberMe from '../../components/auth/RememberMe.vue'
import { gaDataLayerPush, GtmEvent, GtmEventCategory, GtmEventAction, GtmEventLabel } from '../../domain/ga-tracking'
import { truthOf } from '../../lib/boolean.lib'
import { useMfaStore } from '../../stores/mfaStore'
import MFALayout from '../../layouts/MfaLayout.vue';
import Card from '../../components/Card.vue'

export default {
  name: 'ConfirmPhoneNumber',
  components: { DiceRadioContainer, DiceButton, DiceTitle, RememberMe, MFALayout, Card },
  layout: 'MfaLayout',
  data (): { rememberMeChecked: boolean; selectedPhoneNumber?: string} {
    return {
      rememberMeChecked: false,
      selectedPhoneNumber: undefined
    }
  },
  setup() {
    const mfaStore = useMfaStore()
    const baseDomain = process.env.BASE_DOMAIN
    return { mfaStore, baseDomain }
  },
  mounted() {
    this.$data.selectedPhoneNumber = this.mfaStore.phoneNumbers[0]
  },
  created () {
    window.addEventListener('keypress', this.implicitSubmitHandler)
  },
  beforeUnmount () {
    window.removeEventListener('keypress', this.implicitSubmitHandler)
  },
  methods: {
    implicitSubmitHandler: function (e: KeyboardEvent) {
      if (e.key === 'Enter') {
        this.mfaStore.SET_MFA_CHANNEL({
          phoneNumber: this.mfaStore.phoneNumbers[0],
          rememberMeChecked: this.rememberMeChecked
        })
      }
    },
    phoneNumberSelection: function (choice: string) {
      this.selectedPhoneNumber = choice
    },
    rememberMeAction: function (checked: boolean) {
      this.rememberMeChecked = checked
    },
    confirmPhoneNumberCancel: function () {
      gaDataLayerPush(
        GtmEvent.LoginEvent,
        truthOf(this.mfaStore.mfaComplete) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
        GtmEventAction.SignUpSend,
        GtmEventLabel.Cancel
      )
      this.$router.push('/employer/mfa-method')
    }
  }
}
</script>

<style scoped>

</style>
