// Hack for that global variable at dataLayer
declare global {
  interface Window {
    dataLayer: any;
  }
}

export function gaDataLayerPush(
  event: string,
  eventCategory: string,
  eventAction: string,
  eventLabel: string
) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event,
    eventCategory,
    eventAction,
    eventLabel
  });
}

export const GtmEvent = {
  LoginEvent: 'loginEvent'
};

export const GtmEventCategory = {
  Kpi: 'kpi',
  Login: 'mfa_login',
  MfaSignup: 'mfa_signup'
};

export const GtmEventAction = {
  Login: 'login',
  EnableMfa: 'enable_step',
  EnterCode: 'enter_code',
  VerifyOption: 'verify_option',
  VerifyOptionStep: 'verify option step',
  SignUpSend: 'signup_send',
  CodeSend: 'code_send'
};

export const GtmEventLabel = {
  Employer: 'employer',
  EnableAuth: 'enable_authentication',
  Success: 'success',
  Failure: 'fail',
  Skip: 'skip',
  TextMessage: 'text_message',
  EmailMessage: 'email',
  Cancel: 'cancel',
  SendCode: 'send_code',
  Resend: 'resend_code',
  CodeDisplayed: 'enter_code_displayed',
  Email: 'email',
  Text: 'text_message',
  MaxAttemptsExceeded: 'max_attempts_fail'
};
