<template>
  <div class="flex">
    <input id="rememberMe" type="checkbox" name="rememberMe" @click="emitSelection" />
    <label class="pl-2" for="rememberMe">Remember me on this device for 14 days</label>
    <div class="relative">
      <QuestionCircle
        style="cursor: pointer"
        id="remember-me-question"
        fill="#195581"
        :width="25"
        @click.native="togglePopup"
      />
      <DicePopUp :is-visible="popupVisible" :text="popupText" @closePopup="togglePopup" />
    </div>
  </div>
</template>

<script lang="ts">
import QuestionCircle from '../core/icons/QuestionCircle.vue';
import DicePopUp from '../core/DicePopUp/DicePopUp.vue';

export default {
  name: 'RememberMe',
  components: { QuestionCircle, DicePopUp },
  data() {
    return {
      rememberMe: false,
      popupVisible: false,
      popupText:
        'Checking this box means that you will not be prompted to validate an MFA code for 14 days on this device when signing in.'
    };
  },
  methods: {
    emitSelection() {
      this.rememberMe = !this.rememberMe;
      this.$emit('checked', this.rememberMe);
    },
    togglePopup() {
      this.popupVisible = !this.popupVisible;
    }
  }
};
</script>
