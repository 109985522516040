<script lang="ts">
import passwordValidator from 'password-validator'
import PasswordValidationList from '../../components/auth/PasswordValidator/index.vue'
import { useMfaStore } from '../../stores/mfaStore';
import { getQueryStringParam } from '../../lib/url.lib';
import getApolloClient from '../../lib/apollo.client';
import { UpdateForgotPasswordInput, UpdateForgotPasswordResult, updateForgotPasswordMutation } from '../../domain/auth-api/mutations/update-forgot-password';
import { ValidateConfirmationCodeInput, ValidateConfirmationCodeResult, validateConfirmationCodeQuery } from '../../domain/auth-api/queries/validate-confirmation-code';

// Create a schema
const schema = new passwordValidator();

// Add properties to it
schema
  .is().min(8)                                    // Minimum length 8
  .is().max(72)                                   // Maximum length 72
  .has().letters(1)                               // Must have uppercase letters
  .has().digits(1)                                // Must have at least 1 digits


export default {
  name: "ResetPassword",
  components: {
    PasswordValidationList
  },
  setup() {
    const mfaStore = useMfaStore()
    return { mfaStore, baseDomain: process.env.BASE_DOMAIN }
  },
  head () {
    return {
      title: 'Reset Password'
    };
  },
  async beforeMount() {
    const [confirmationCode, principalId] = getQueryStringParam(['c', 'u']);
    if (!(confirmationCode && principalId)) {
      console.error('Query string paramaters missing.', { principalId, confirmationCode });
      return;
    }
    await this.validateConfirmationCode(principalId, confirmationCode)
  },
  created () {
    window.addEventListener('keypress', this.implicitSubmitHandler);
  },
  deactivated () {
    window.removeEventListener('keypress', this.implicitSubmitHandler);
  },
  data () {
    return {
      passwordEntered: true,
      confirmPassword: '',
      confirmPasswordEntered: true,
      passwordInputMismatch: false,
      timeout: null as ReturnType<typeof setTimeout> | null,
      debouncedPassword: '',
      processing: false,
      errorMessage: '',
      isValidatingConfirmationCode: true,
      isConfirmationCodeInvalid: false,
    }
  },
  computed: {
    password: {
      get () {
        return this.debouncedPassword
      },
      set (val: string) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.debouncedPassword = val
        }, 500)
      }
    }
  },
  methods: {
    /**
     * Handle the implicit form submit requirement for submitting the login
     * by checking if the enter key was pressed. This method will submit the form
     * even when the form in not in focus.
     * @param {KeyboardEvent} e
     */
    implicitSubmitHandler (e: KeyboardEvent) {
      if (e.key === 'Enter') {
        this.submit()
      }
    },
    async submit () {
      const [c, u] = getQueryStringParam(['c', 'u']);
      if (!(c && u)) {
        console.warn('Query string paramaters missing.');
        return;
      }

      if (this.processing) return

      if (this.validatePasswordInput()) {
        const {data} = await this.apolloChangePassword(u, this.password, c);

        if (data?.updateForgotPassword?.status === 'SUCCESS') {
          this.$emit('submit', {})
        } else {
          this.errorMessage = 'Reset password unsuccessful, try resending email or contacting support'
        }
      }
    },

    passwordInputMatched () {
      this.passwordInputMismatch = this.debouncedPassword !== this.confirmPassword
    },

    passwordSufficientLength () {
      this.passwordEntered = (this.debouncedPassword.length > 0)
    },

    confirmPasswordSufficientLength () {
      this.confirmPasswordEntered = (this.confirmPassword.length > 0)
    },
    confirmAllowedSpecialCharacters () {
      const allowedCharacters = /^[a-zA-Z0-9\^\$\*\.\[\]\{\}\(\)\?\"!@#%&\/\\,><':;\|_~` =+\-]*$/
      return allowedCharacters.test(this.debouncedPassword)
    },

    validatePasswordInput () {
      this.passwordSufficientLength()
      this.confirmPasswordSufficientLength()
      this.passwordInputMatched()

      return this.debouncedPassword.length >= 8 && !this.passwordInputMismatch && schema.validate(this.debouncedPassword) && this.confirmAllowedSpecialCharacters()
    },

    async apolloChangePassword (u:string, password: string, c:string) {
      try {
        this.processing = true
        return await getApolloClient().mutate<
          UpdateForgotPasswordResult,
          UpdateForgotPasswordInput
        >({
          mutation: updateForgotPasswordMutation,
          variables: {
            username: u,
            password: password.trim(),
            confirmationCode: c
          }
        });
      } catch (e) {
        throw new Error(e instanceof Error ? e.message : 'Unexpected error while resetting password.')
      } finally {
        this.processing = false
      }
    },
    async validateConfirmationCode(principalId:string, confirmationCode:string) {
      this.isValidatingConfirmationCode = true
      try {
        const response = await getApolloClient().query<
          ValidateConfirmationCodeResult,
          ValidateConfirmationCodeInput
        >({
          query: validateConfirmationCodeQuery,
          variables: {
            principalId,
            confirmationCode
          }
        });
        this.isConfirmationCodeInvalid = !response.data?.validateConfirmationCode?.valid
      } catch (e) {
        console.error("Unexpected error while validating confirmation code", e)
      }
      this.isValidatingConfirmationCode = false
      return false
    }
  }
}
</script>


<style>
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>

<template>
  <div
    v-if="!isValidatingConfirmationCode && isConfirmationCodeInvalid"
    data-cy="invalidConfirmationCode"
  >
    <h1 class="dice-text-100 pb-4 text-center" data-cy="invalidConfirmationCodeHeader">Reset password error</h1>
    <p data-cy="invalidConfirmationCodeText" class="dice-text-500 text-center">
      The confirmation code you provided has either expired or has already been used.<br />
      Please make sure you are using the latest reset link sent to your email or <br />
      request a new password reset link if needed.
    </p>
    <a class="dice-btn py-3 px-6 my-12 w-full block text-center rounded-full" href="/employer/forgotten-password">
      Reset Password
    </a>
    <hr class="max-w-3xl" />
    <div class="grid grid-rows-2 md:gap-10 pt-12 pb-0">
      <div>
        <p class="dice-text text-xl pb-2" data-cy="candidateLoginHeader">
          Are you having trouble resetting your password?
        </p>
        <a :href="`${baseDomain}/about/contact-us`" class="dice-link pb-3 pt-3" data-cy="candidateLoginLink">Contact Support</a>
      </div>
    </div>
  </div>
  <div
    v-if="!isConfirmationCodeInvalid"
    :class="{ 'flex-col': true, 'disabled': isValidatingConfirmationCode }"
    data-cy="resetPassword"
  >
    <h1 class="dice-text-100 text-center pb-12">Reset your password</h1>
    <form class="w-full max-w-lg" autocomplete="on" :aria-disabled="isValidatingConfirmationCode" :disabled="isValidatingConfirmationCode">
      <div class="pb-4">
        <label id="passwordLabel" class="dice-label mb-1" for="password">
          Password <span class="text-red-500">*</span>
        </label>
        <input
          id="password"
          name="password"
          type="password"
          v-model="password"
          autofocus
          autocomplete="new-password"
          :class="!passwordEntered ? 'dice-input border-red-500' : 'dice-input' "
        />
        <div id="errorTextPassword" class="h-3 mb-3">
          <p v-if="!passwordEntered" class="dice-error-text">You must provide information</p>
        </div>
        <PasswordValidationList :password="debouncedPassword"/>
      </div>
      <div id="confirmPasswordGroup">
        <label class="dice-label mb-1" for="confirmPasswordInput">
          Re-enter Password <span class="text-red-500">*</span>
        </label>
        <input
          id="confirmPasswordInput"
          name="confirmPassword"
          type="password"
          v-model="confirmPassword"
          class=""
          autocomplete="new-password"
          :class="!confirmPasswordEntered ? 'dice-input border-red-500' : 'dice-input' "
        />
        <div id="errorTextPasswordConfirm" class="h-12">
          <p v-if="!confirmPasswordEntered" class="dice-error-text">You must provide information</p>
          <p v-if="passwordInputMismatch" class="dice-error-text">Passwords entered do not match</p>
          <p v-else-if="errorMessage" class="dice-error-text">{{ errorMessage }}</p>
        </div>
      </div>
      <button role=button @click.prevent="submit" class="dice-btn py-3 w-full rounded-full" type="button">
        Submit
        <span v-if="processing === true" class="spinner-border animate-spin inline-block h-4 border-1 rounded-full" role="status">
              <span class="visually-hidden">Loading...</span>
        </span>
      </button>
    </form>
  </div>
</template>

