import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';

const cache = new InMemoryCache();
let _client: ApolloClient<NormalizedCacheObject> | undefined = undefined;

const getApolloClient = () => {
  if (!_client) {
    _client = new ApolloClient({
      cache,
      uri: process.env.AUTH_API_GRAPHQL_URI
    });
  }
  return _client;
};

export default getApolloClient;
