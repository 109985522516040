import consola from 'consola'
import { v4 as uuidv4 } from 'uuid'
import { setMfaChannelMutation, SetMfaChanneInput, SetMfaChanneResult } from '../domain/auth-api/mutations/mfa-channel'
import { signInUserMutation, SignInUserInput, SignInUserResult, SignInUserResultSchema } from '../domain/auth-api/mutations/signin-user';
import { RespondToMfaChallengeInput, respondToMfaChallengeMutation, RespondToMfaChallengeResult,  } from '../domain/auth-api/mutations/respond-to-mfa-challenge'

import { truthOf } from '../lib/boolean.lib'

import {
  formatApiPhoneNumber
} from '../lib/phone-number.lib'
import { gaDataLayerPush, GtmEvent, GtmEventAction, GtmEventCategory, GtmEventLabel } from '../domain/ga-tracking'
import { ref } from 'vue';
import { defineStore } from 'pinia';
import getApolloClient from '../lib/apollo.client';
import {getEnvironment, redirect} from '../lib/url.lib';
import {setTokenCookies, setTokenLocalStorage} from '../domain/token';
import { useRouter } from 'vue-router';
import { RequestMfaCodeInput, requestMfaCodeMutation, RequestMfaCodeResult } from '../domain/auth-api/mutations/requestMfaCode';


export const useMfaStore = defineStore('mfaStore', () => {
    const router = useRouter();

    /** State */
    const email = ref();
    const password = ref();
    const phoneNumbers = ref<string[]>([]);
    const selectedPhoneNumber = ref<string | undefined>(undefined);
    const mfaMethod = ref('Email');
    const queryParams = ref<string | undefined>()
    const session = ref<string | undefined>();
    const deliveryTarget = ref<string | undefined>();
    const showChallengeCodeError = ref(false);
    const mfaChannelOptions = ref(['Email']);
    const toast = ref({
        type: 'success',
        text: 'This is a toast message',
        show: false
    });
    const maximumCodeValidationAttemptsMet = ref(false);
    const smsFailure = ref(false);
    const loading = ref(false);
    const userOptedIn = ref(false);
    const mfaRequired = ref(false);
    const mfaComplete = ref(false);
    const deviceId = ref(localStorage.getItem('IDTRememberMeDevice')); // TODO: migrate to token store
    const ssoLink = ref();
    const resendDisabled = ref(true);

    /** Mutations */
    function SAVE_EMAIL (newEmail: string) {
        email.value = newEmail
    }
    function SAVE_PASSWORD (newPassword: string) {
        password.value = newPassword
    }
    function SAVE_MFA_METHOD (newMfaMethod: string) {
        mfaMethod.value = newMfaMethod
    }
    function SAVE_MFA_REQUIRED (newMfaRequired?: boolean) {
        mfaRequired.value = newMfaRequired ?? false
    }
    function SAVE_MFA_COMPLETE (newMfaComplete?: boolean) {
        mfaComplete.value = newMfaComplete ?? false
    }
    function SAVE_SELECTED_PHONE_NUMBER (newSelectedPhoneNumber: string) {
        selectedPhoneNumber.value = newSelectedPhoneNumber
    }
    function SAVE_QUERY_PARAMS (newQueryParams: string) {
        queryParams.value = newQueryParams
    }
    function SAVE_SESSION (newSession?: string) {
        session.value = newSession
    }
    function SAVE_DELIVERY_TARGET (newDeliveryTarget?: string) {
        deliveryTarget.value = newDeliveryTarget
    }
    function SEND_SMS_CODE_FAILURE () {
        smsFailure.value = true
    }
    function CONFIRM_CHALLENGE_CODE_SUCCESS () {
        // disable loading state?
        toast.value = { ...toast.value, show: false }
        showChallengeCodeError.value = false
    }
    function CONFIRM_CHALLENGE_CODE_FAILURE () {
        showChallengeCodeError.value = true
    }
    // TODO: Figure out how this gets migrated with mutation having same name
    // was ENABLE_AUTHENTICATION
    function ENABLE_MFA_PHONE (newPhoneNumbers:string[]) {
        phoneNumbers.value = newPhoneNumbers
        mfaChannelOptions.value = Array.from(new Set(['Text Message', ...mfaChannelOptions.value]))
        mfaMethod.value = 'Text Message'
    }
    function SHOW_TOAST ({ type, text, show }: { type: string; text: string; show: boolean}) {
        toast.value = { type, text, show }
    }
    function HIDE_TOAST () {
        toast.value = {... toast.value, show: false}
    }
    function MAXIMUM_CODE_VALIDATION_ATTEMPTS_MET (hasMetAttempts: boolean) {
        maximumCodeValidationAttemptsMet.value = hasMetAttempts
    }
    function SHOW_LOADER (showLoader: boolean) {
        loading.value = showLoader
    }
    function USER_OPTED_IN () {
        userOptedIn.value = true
    }
    function SAVE_DEVICE_ID (newDeviceId: string | null) {
        deviceId.value = newDeviceId
    }
    function SAVE_SSO_LINK (newSsoLink: string) {
        ssoLink.value = newSsoLink
    }

    /** Actions */
    async function SEND_CHALLENGE_CODE (deliveryTarget?: string) {
        try {
          const response = await getApolloClient().mutate<RequestMfaCodeResult, RequestMfaCodeInput>({
            mutation: requestMfaCodeMutation,
            variables: {
                email: email.value,
                password: password.value,
            },
            fetchPolicy: 'no-cache'
          })

          if (response && response.data && response.data.requestMfaCode) {
            SAVE_SESSION(response.data.requestMfaCode.session);
            SHOW_TOAST({
                type: 'success',
                text: 'Authentication code sent.',
                show: true
            });

            if (deliveryTarget !== 'codeResend' && !response.data.requestMfaCode.smsFailure) {
                SAVE_DELIVERY_TARGET(deliveryTarget)
            }

            if (response.data.requestMfaCode.smsFailure) {
              gaDataLayerPush(
                GtmEvent.LoginEvent,
                'not_set',
                GtmEventAction.EnterCode,
                'code_sent_by_email_not_sms'
              )
              SEND_SMS_CODE_FAILURE()
              SAVE_DELIVERY_TARGET(email.value)
              SHOW_TOAST({
                type: 'success',
                text: 'Authentication code sent to ',
                show: true,
              })
            }
          }
        } catch (e) {
          SAVE_DELIVERY_TARGET(deliveryTarget)
        } finally {
          SHOW_LOADER(false)

          if (deliveryTarget !== 'codeResend') {
            gaDataLayerPush(
              GtmEvent.LoginEvent,
              truthOf(mfaComplete.value) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
              GtmEventAction.SignUpSend,
              GtmEventLabel.SendCode
            )
            router.push('/employer/challenge-response')
          } else {
            gaDataLayerPush(
              GtmEvent.LoginEvent,
              truthOf(mfaComplete.value) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
              GtmEventAction.EnterCode,
              GtmEventLabel.Resend
            )
          }
        }
      }

    async function SET_MFA_CHANNEL (formData: { rememberMeChecked: boolean, phoneNumber?: string, email?: string }) {
        if (loading.value) {
            return
        }
        const channelType = mfaMethod.value === 'Email' ? 'email' : 'sms'

        let phoneNumber = ''

        if (formData.rememberMeChecked) {
            SAVE_DEVICE_ID(deviceId.value ?? uuidv4())
        } else {
            // if unchecked we want the state for device id to be null, so it isn't sent in upon responding to the MFA challenge
            SAVE_DEVICE_ID(null)
        }

        if (channelType === 'sms') {
            phoneNumber = formatApiPhoneNumber(formData.phoneNumber)
            SAVE_SELECTED_PHONE_NUMBER(formData.phoneNumber)
        }

        SHOW_LOADER(true)

        try {
        const response = await getApolloClient().mutate<SetMfaChanneResult, SetMfaChanneInput>({
            mutation: setMfaChannelMutation,
            variables: {
            email: email.value,
            channelType,
            phoneNumber
            },
            fetchPolicy: 'no-cache'
        })

        if (response && response.data?.setMfaChannel.status === 'SUCCESS') {
            const deliveryTarget = channelType === 'email' ? email.value : selectedPhoneNumber.value
            SEND_CHALLENGE_CODE(deliveryTarget)
        }
        } catch (e) {
            consola.log('Error sending challenge code', e)
            SHOW_LOADER(false)
        }
      }

    // TODO: Simplify logic
    async function VALIDATE_CHALLENGE_CODE (challengeCode?:string) {

        if(!challengeCode || challengeCode.length === 0){
            consola.log('Empty Input')
            SHOW_LOADER(false)
            CONFIRM_CHALLENGE_CODE_FAILURE()
            SHOW_TOAST({
              type: 'error',
              text: 'One time code is required.',
              show: true,
            })
            return
        }

        if(loading.value) return;

        // TODO: Make sure this is handled appropriatley
        if(!session.value) {
            consola.error("Missing session")
            return
        }

        SHOW_LOADER(true)

        try {
            const response = await getApolloClient().mutate<RespondToMfaChallengeResult, RespondToMfaChallengeInput>({
                mutation: respondToMfaChallengeMutation,
                variables: {
                    email: email.value,
                    password: password.value,
                    code: challengeCode,
                    session: session.value,
                    deviceId: deviceId.value ?? undefined,
                },
                fetchPolicy: 'no-cache'
            })

            if (response.data?.respondToMfaChallenge.status === 'SUCCESS') {
                if (deviceId.value) {
                    localStorage.setItem('IDTRememberMeDevice', deviceId.value)
                }
                const mfaChallengeResponse = response.data.respondToMfaChallenge

                gaDataLayerPush(
                    GtmEvent.LoginEvent,
                    truthOf(mfaComplete.value) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
                    GtmEventAction.EnterCode,
                    GtmEventLabel.Success
                )
                gaDataLayerPush(
                    GtmEvent.LoginEvent,
                    GtmEventCategory.Kpi,
                    GtmEventAction.Login,
                    GtmEventLabel.Employer
                )
                if (getEnvironment() === 'prod' || getEnvironment() === 'pilot') {
                    setTokenCookies(
                      mfaChallengeResponse.access_token,
                      mfaChallengeResponse.identity_token,
                      mfaChallengeResponse.refresh_token
                    )
                }
                setTokenLocalStorage(
                  mfaChallengeResponse.identity_token,
                  mfaChallengeResponse.refresh_token
                )
                USER_OPTED_IN()
                SHOW_LOADER(false)
                redirect('/employer/login-landing', queryParams.value)
            } else if (
                response.data?.respondToMfaChallenge.attempts_remaining === 0 &&
                response.data?.respondToMfaChallenge?.status !== 'Invalid session for the user.'
            ) {
                gaDataLayerPush(
                    GtmEvent.LoginEvent,
                    truthOf(mfaComplete.value) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
                    GtmEventAction.EnterCode,
                    GtmEventLabel.MaxAttemptsExceeded
                )

                SHOW_LOADER(false)
                MAXIMUM_CODE_VALIDATION_ATTEMPTS_MET(true)
                SHOW_TOAST({
                    type: 'error',
                    text: 'Maximum attempts exceeded for current code.',
                    show: true,
                })
            } else if (
                response.data?.respondToMfaChallenge.attempts_remaining !== 0 &&
                response.data?.respondToMfaChallenge.new_session
            ) {
                gaDataLayerPush(
                    GtmEvent.LoginEvent,
                    truthOf(mfaComplete.value) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
                    GtmEventAction.EnterCode,
                    GtmEventLabel.Failure
                )
                SHOW_LOADER(false)
                CONFIRM_CHALLENGE_CODE_FAILURE()
                SAVE_SESSION(response.data.respondToMfaChallenge.new_session)
                SHOW_TOAST({
                    type: 'error',
                    text: `${response.data.respondToMfaChallenge.attempts_remaining} attempts left.`,
                    show: true
                })
            } else {
                gaDataLayerPush(
                    GtmEvent.LoginEvent,
                    truthOf(mfaComplete) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
                    GtmEventAction.EnterCode,
                    GtmEventLabel.Failure
                )
                SHOW_LOADER(false)
                CONFIRM_CHALLENGE_CODE_FAILURE()
                SHOW_TOAST({
                    type: 'error',
                    text: 'Invalid Code, please try resending a new code.',
                    show: true,
                })
            }
        } catch (e) {
            consola.log('Validate Challenge Code error')
            gaDataLayerPush(
            GtmEvent.LoginEvent,
            truthOf(mfaComplete) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
            GtmEventAction.EnterCode,
            GtmEventLabel.Failure
            )
            SHOW_LOADER(false)
            CONFIRM_CHALLENGE_CODE_FAILURE()
            SHOW_TOAST({
                type: 'error',
                text: 'There was an issue verifying your code, please resend a new code or contact support.',
                show: true,
            })
        }
    }

    // TODO: Figure out how this gets migrated with mutation having same name
    function ENABLE_AUTHENTICATION () {
        if (!truthOf(mfaComplete.value)) {
          gaDataLayerPush(
            GtmEvent.LoginEvent,
            GtmEventCategory.MfaSignup,
            GtmEventAction.EnableMfa,
            GtmEventLabel.EnableAuth
          )
        }
        router.push('/employer/mfa-method')
      }

    async function RESET_MFA_CHANNEL () {
        try {
            if (!userOptedIn.value) {
            await getApolloClient().mutate<SetMfaChanneResult, SetMfaChanneInput>({
                mutation: setMfaChannelMutation,
                variables: {
                    email: email.value,
                    channelType: '',
                    phoneNumber: ''
                },
                fetchPolicy: 'no-cache'
            })
            }
        } catch (e) {
            consola.log('Error sending challenge code', e)
        } finally {
            router.back()
        }
    }

    return {
        /** State */
        email,
        password,
        phoneNumbers,
        selectedPhoneNumber,
        mfaMethod,
        queryParams,
        session,
        deliveryTarget,
        showChallengeCodeError,
        mfaChannelOptions,
        toast,
        maximumCodeValidationAttemptsMet,
        smsFailure,
        loading,
        userOptedIn,
        mfaRequired,
        mfaComplete,
        deviceId,
        ssoLink,
        resendDisabled,

        /** Getters */
        defaultMfaMethod: mfaMethod,

        /** Mutations */
        SAVE_EMAIL,
        SAVE_PASSWORD,
        SAVE_MFA_METHOD,
        SAVE_MFA_REQUIRED,
        SAVE_MFA_COMPLETE,
        SAVE_SELECTED_PHONE_NUMBER,
        SAVE_QUERY_PARAMS,
        SAVE_SESSION,
        SAVE_DELIVERY_TARGET,
        SEND_SMS_CODE_FAILURE,
        CONFIRM_CHALLENGE_CODE_SUCCESS,
        CONFIRM_CHALLENGE_CODE_FAILURE,
        ENABLE_MFA_PHONE,
        SHOW_TOAST,
        HIDE_TOAST,
        MAXIMUM_CODE_VALIDATION_ATTEMPTS_MET,
        SHOW_LOADER,
        USER_OPTED_IN,
        SAVE_DEVICE_ID,
        SAVE_SSO_LINK,

        /** Actions */
        SEND_CHALLENGE_CODE,
        SET_MFA_CHANNEL,
        VALIDATE_CHALLENGE_CODE,
        ENABLE_AUTHENTICATION,
        RESET_MFA_CHANNEL
    }
});