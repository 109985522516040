<template>
  <SsoLayout class="flex">
    <Card>
    <component
      :is="step"
      :email-input="emailInput"
      @back="back"
      :onSubmit="submit"
    />
    </Card>
  </SsoLayout>
</template>
<script lang="ts">

import InitiateForgotPassword from '../../components/auth/InitiateForgotPassword.vue'
import InitiateForgotPasswordSuccess from '../../components/auth/InitiateForgotPasswordSuccess.vue'
import SsoLayout from '../../layouts/SsoLayout.vue';
import Card from '../../components/Card.vue'

export default {
  name: 'ForgottenPassword',
  components: { SsoLayout, Card },
  data () {
    return {
      current: 'email',
      emailInput: ''
    }
  },
  computed: {
    step () {
      return this.current === 'email' ? InitiateForgotPassword : InitiateForgotPasswordSuccess
    }
  },
  methods: {
    back () {
      this.current = 'email'
    },

    submit () {
      this.current = 'success'
    }
  }
}
</script>
