import { z } from 'zod';
import gql from 'graphql-tag';

export const validateConfirmationCodeQuery = gql`
  query($principalId: String!, $confirmationCode: String!)  {
    validateConfirmationCode (
        input: {
            principalId: $principalId,
            confirmationCode: $confirmationCode
        }
    ){
        valid
    }
  }
`;

export const ValidateConfirmationCodeInputSchema = z.object({
  principalId: z.string().email(),
  confirmationCode: z.string(),
})

export const ValidateConfirmationCodeResultSchema = z.object({
  validateConfirmationCode: z.object({
    valid: z.boolean(),
  })
})

export type ValidateConfirmationCodeInput = z.infer<typeof ValidateConfirmationCodeInputSchema>;
export type ValidateConfirmationCodeResult = z.infer<typeof ValidateConfirmationCodeResultSchema>;