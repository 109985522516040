<script lang="ts">
import DefaultLayout from '../../layouts/default.vue';
import PasswordValidationList from '../../components/auth/PasswordValidator/index.vue'
import getApolloClient from '../../lib/apollo.client';
import { ChangePasswordInput, ChangePasswordResult, changePasswordMutation } from '../../domain/auth-api/mutations/change-password'
import SsoLayout from "../../layouts/SsoLayout.vue";
import Card from '../../components/Card.vue'

export default {
  name: "ChangePassword",
  components: {
    PasswordValidationList,
    SsoLayout,
    Card
  },
  setup() {
    const baseDomain = process.env.BASE_DOMAIN
    return { baseDomain }
  },
  head() {
    return {
      title: 'Change Password'
    };
  },
  async beforeMount() {
    window.identityLogin.addOnFailureHook(this.kickToLogin)
    await window.identityLogin.startup()
    try {
      this.ssoUser = window.identityLogin.isSsoUser()
    } catch {
      // isSsoUser will throw a ReferenceError if it can't find the accessJwt or decode it
      // however, startup will already be kicking the user out by now, so there is no need to fire processOnFailurHookList here
      console.error('User has no client_id')
    }
  },
  created() {
    window.addEventListener('keypress', this.implicitSubmitHandler);
  },
  deactivated() {
    window.removeEventListener('keypress', this.implicitSubmitHandler);
  },
  data() {
    return {
      changePasswordSuccess: false,
      passwordEntered: true,
      existingPassword: '',
      existingPasswordEntered: true,
      confirmPassword: '',
      confirmPasswordEntered: true,
      passwordInputMismatch: false,
      timeout: null as ReturnType<typeof setTimeout> | null,
      debouncedPassword: '',
      processing: false,
      errorMessage: '',
      ssoUser: false,
    }
  },
  computed: {
    password: {
      get() {
        return this.debouncedPassword
      },
      set(val: string) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.debouncedPassword = val
        }, 500)
      }
    }
  },
  methods: {
    /**
     * Handle the implicit form submit requirement for submitting the login
     * by checking if the enter key was pressed. This method will submit the form
     * even when the form in not in focus.
     * @param {KeyboardEvent} e
     */
    implicitSubmitHandler(e: KeyboardEvent) {
      if (e.key === 'Enter') {
        this.submit()
      }
    },
    async submit() {
      if (this.processing) return

      if (this.validatePasswordInput()) {
        // need the username
        const { principal_id: username } = window.identityLogin.decodeJwt(window.identityLogin.getAccessJwt())
        if (!username) {
          window.identityLogin.processOnFailureHookList(undefined, {}, 'No principal id - likely a null access token')
          return // would firing the OnFailureHookList do that by navigating away or would I need this return here (or some other way to break the execution)
        }
        await this.apolloChangePassword(username, this.password.trim(), this.existingPassword.trim());
      }
    },

    passwordInputMatched() {
      this.passwordInputMismatch = this.debouncedPassword !== this.confirmPassword
    },

    existingPasswordPresent() {
      this.existingPasswordEntered = (this.existingPassword.length > 0)
    },
    passwordPresent() {
      this.passwordEntered = (this.debouncedPassword.length > 0)
    },
    confirmPasswordPresent() {
      this.confirmPasswordEntered = (this.confirmPassword.length > 0)
    },
    allPasswordFieldsContainText () {
      this.existingPasswordPresent()
      this.passwordPresent()
      this.confirmPasswordPresent()

      return this.existingPasswordEntered &&
        this.passwordEntered &&
        this.confirmPasswordEntered
    },
    validatePassword() {
      // At least One Letter = (?=.*[A-Za-z])
      // At least One Number = (?=.*\d)
      // Only the allowed symbols = [A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?\"!@#%&\/\\,><':;\|_~` =+\-]
      // Between 8 and 72 characters = {8,72}
      const schema = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?\"!@#%&\/\\,><':;\|_~` =+\-]{8,72}$/
      return schema.test(this.debouncedPassword)
    },

    validatePasswordInput() {
      this.passwordInputMatched()
      const allFieldsContainTextAndInputsMatch = this.allPasswordFieldsContainText() && !this.passwordInputMismatch
      if (allFieldsContainTextAndInputsMatch) {
          return this.validatePassword()
      }
      return false
    },

    async apolloChangePassword(username: string, newPassword: string, existingPassword: string) {
      try {
        this.processing = true
        const response = await getApolloClient().mutate<
          ChangePasswordResult,
          ChangePasswordInput
        >({
          mutation: changePasswordMutation,
          variables: {
            username,
            existingPassword,
            newPassword,
          }
        });
        if (response.data.changePassword.status === 'SUCCESS') this.changePasswordSuccess = true
        else this.errorMessage = response.data.changePassword.status
      } catch (e) {
        console.error(e)
        this.errorMessage = 'Change password unsuccessful, try resending email or contacting support'
      } finally {
        this.processing = false
      }
    },

    kickToLogin() {
      console.info('User access expired; returning to login page')
      window.identityLogin.logout()
      window.location.href = `${this.baseDomain}/employer/login`
    }
  }
}
</script>


<template>
  <SsoLayout class="flex">
    <Card>
    <div class="flex-col">
      <h1 class="dice-text-100 pb-12 text-center">Change your password</h1>
      <form v-if="!ssoUser && !changePasswordSuccess" class="w-full max-w-lg" autocomplete="on">
        <div class="pb-3">
          <label id="passwordLabel" class="dice-label mb-1" for="password">
            Current Password <span class="text-red-500">*</span>
          </label>
          <input id="password" name="password" type="password" v-model="existingPassword" autofocus autocomplete="password"
            :class="!existingPasswordEntered ? 'dice-input border-red-500' : 'dice-input'" />
          <div id="errorTextPassword" class="h-3 mb-3">
            <p v-if="!existingPasswordEntered" class="dice-error-text">You must provide information</p>
          </div>
        </div>
        <div class="pb-6">
          <label id="passwordLabel" class="dice-label mb-1" for="newPassword">
            New Password <span class="text-red-500">*</span>
          </label>
          <input id="newPassword" name="newPassword" type="password" v-model="password" autofocus autocomplete="new-password"
            :class="!passwordEntered ? 'dice-input border-red-500' : 'dice-input'" />
          <div id="errorTextPassword" class="h-3 mb-3">
            <p v-if="!passwordEntered" class="dice-error-text">You must provide information</p>
          </div>
          <PasswordValidationList :password="debouncedPassword" />
        </div>
        <div id="confirmPasswordGroup">
          <label class="dice-label mb-1" for="confirmPasswordInput">
            Confirm New Password <span class="text-red-500">*</span>
          </label>
          <input id="confirmPasswordInput" name="confirmPassword" type="password" v-model="confirmPassword" class=""
            autocomplete="new-password" :class="!confirmPasswordEntered ? 'dice-input border-red-500' : 'dice-input'" />
          <div id="errorTextPasswordConfirm" class="h-12">
            <p v-if="!confirmPasswordEntered" class="dice-error-text">You must provide information</p>
            <p v-if="passwordInputMismatch" class="dice-error-text">Passwords entered do not match</p>
            <p v-else-if="errorMessage" class="dice-error-text">{{ errorMessage }}</p>
          </div>
        </div>
        <button role=button @click.prevent="submit" class="dice-btn py-3 w-full rounded-full" type="button">
          Submit
          <span v-if="processing === true" class="spinner-border animate-spin inline-block h-4 border-1 rounded-full"
            role="status">
            <span class="visually-hidden">Loading...</span>
          </span>
        </button>
      </form>
      <div v-if="changePasswordSuccess || ssoUser">
        <p class="mb-6" v-if="changePasswordSuccess">Your password has been successfully changed.</p>
        <p class="mb-6" v-if="ssoUser">SSO Users cannot updated their passwords. Please consult your SSO Provider.</p>
        <a :href="`${baseDomain}/employer/dashboard`" role=button class="dice-btn py-3 w-full text-center rounded-full" type="button">Return to Dashboard</a>
      </div>
    </div>
    </Card>
  </SsoLayout>
</template>