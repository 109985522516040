import { z } from 'zod';
import gql from 'graphql-tag';

export const checkSsoStatusQuery = gql`
  query ($email: String!) {
    checkSsoStatus(input: { email: $email }) {
      status
      ssoStatus {
        enabled
        link
      }
    }
  }
`;

export const CheckSsoStatusInputSchema = z.object({
  email: z.string().email()
})

export const CheckSsoStatusResultSchema = z.object({
  checkSsoStatus: z.object({
    status: z.enum([
      'SUCCESS',
      'Error retrieving sso status',
      'Invalid user type'
    ]),
    ssoStatus: z.object({
      enabled: z.boolean(),
      link: z.string().nullable()
    })
  })
})

export type CheckSsoStatusInput = z.infer<typeof CheckSsoStatusInputSchema>;
export type CheckSsoStatusResult = z.infer<typeof CheckSsoStatusResultSchema>;