<script lang="ts" setup>
// Queries & Mutations
import DiceModal from '../../components/core/DiceModal/DiceModal.vue';
import CallIcon from '../../components/core/icons/CallIcon.vue';
import { useTokenStore } from '../../stores/tokenStore';
import { ref, onMounted } from 'vue'
import { useMfaStore } from '../../stores/mfaStore'
import { redirect } from '../../lib/url.lib'
import SsoLayout from '../../layouts/SsoLayout.vue';
import Card from '../../components/Card.vue'

const baseDomain = process.env.BASE_DOMAIN;
const processingSignIn = ref(false)
const isVisible = ref(false)
const mfaStore = useMfaStore()
const tokenStore = useTokenStore()

/**
 * On component mount, wait 3 seconds and then call the redirectToSsoIdp function automatically
 */
onMounted(() => {
  setTimeout(() => {
    redirectToSsoIdp(mfaStore.ssoLink)
  }, 3000)
})

/** Actions */
const redirectToSsoIdp = (link: string) => {
  let queryParams = mfaStore.queryParams
  if (queryParams) {
    // turn ?state into &state for cognito /authorize URL
    redirect(link + queryParams.replace('?', '&'))
  } else {
    redirect(link)
  }
}

const openModal = () => {
  isVisible.value = true
}

const closeModal = () => {
  isVisible.value = false
}

const submit = async () => {
  if (processingSignIn.value) { return }
  processingSignIn.value = true
  redirectToSsoIdp(mfaStore.ssoLink)
}
</script>

<template>
  <SsoLayout class="flex">
    <DiceModal data-cy="contactSupportModal" :is-visible="isVisible" @cancel="closeModal" @confirm="closeModal">
      <template #title>
        Contact Support
      </template>
      <template #body>
        <div class="flex mb-10">
          <div class="flex-row">
            <div class="flex items-start m-auto">
              <CallIcon color="dice-blue-400" :width="20" />
              <div class="pl-1">
                <span id="phoneText" class="dice-text-500 dice-link">888-321-DICE</span><span
                  id="diceDigits"
                  class="dice-text-500"
              >(3423)</span>
              </div>
            </div>
            <p id="hoursOperation" class="dice-text-500">
              Monday - Friday, 8am to 5pm CST
            </p>
            <div class="flex items-center m-auto pt-4">
              <img src="../../assets/icons/mail.svg" alt="mail" class="w-20px">
              <a href="mailto:support@dice.com" role="link" class="pl-1 dice-link">support@dice.com</a>
            </div>
          </div>
        </div>
      </template>
      <template #footer />
    </DiceModal>

    <Card>
      <h1 id="signin" title="sign-in" class="dice-text-100 text-center pb-2" data-cy="signInHeader">
        SSO Sign In
      </h1>
      <h2 id="login" class="text-xl text-center font-normal break-words pb-12">
        <p>Redirecting you to your Single Sign On Provider...</p>
        <p>If you are not redirected, please click Sign In to continue.</p>
      </h2>
      <form name="login" autocomplete="on" @submit.prevent="submit">

        <button role="button" class="dice-btn py-3 px-6 w-full rounded-full mb-12" data-cy="signInButton" type="submit">
          Sign In
          <span v-if="processingSignIn === true" class="spinner-border animate-spin inline-block w-4 h-4 border-1 rounded-full" role="status">
            <span class="visually-hidden">Loading...</span>
          </span>
        </button>
        <hr>
        <div class="grid grid-rows-2 md:gap-10 pt-12">
          <div>
            <p class="dice-text text-xl pb-2" data-cy="candidateLoginHeader">
              Partner with us to help you hire top tech talent.
            </p>
            <a :href="`${baseDomain}/hiring`" class="dice-link pb-3 pt-3" data-cy="candidateLoginLink">Contact Dice</a>
          </div>
          <div>
            <p class="dice-text text-xl pt-4 pb-1 md:pt-0" data-cy="hiringRedirectHeader">
              Are you searching for a job in tech?
            </p>
            <a :href="`${baseDomain}/dashboard/login`" class="dice-link hiring-link pb-3 pt-3" data-cy="hiringRedirectLink">Register Now</a>
          </div>
        </div>
      </form>
    </Card>
  </SsoLayout>
</template>
