import { z } from 'zod';
import gql from 'graphql-tag';

export const changePasswordMutation = gql`
  mutation ($username: String!, $existingPassword: String!, $newPassword: String!) {
    changePassword(
      input: { username: $username, existingPassword: $existingPassword, newPassword: $newPassword }
    ) {
      status
    }
  }
`;

export const ChangePasswordInputSchema = z.object({
  username: z.string(),
  existingPassword: z.string(),
  newPassword: z.string()
});

export const ChangePasswordResultSchema = z.object({
  changePassword: z.object({
    status: z.string()
  })
});

export type ChangePasswordInput = z.infer<typeof ChangePasswordInputSchema>;
export type ChangePasswordResult = z.infer<typeof ChangePasswordResultSchema>;
