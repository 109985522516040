<template>
  <h1 class="text-dice-gray-700 font-semibold text-3xl md:text-3xl max-w-full pb-6">
    {{ titleText }}
  </h1>
</template>

<script lang="ts">
export default {
  name: 'DiceTitle',
  props: {
    titleText: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped></style>
