<template>
  <div v-if="isVisible" class="modal-wrapper max-w-full">
    <div
      class="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto"
      @click="emitCancel"
    >
      <div class="flex flex-col pt-2 md:mt-6 h-fit max-w-full shadow-xl bg-white px-3" @click.stop>
        <div class="pt-1 pb-4 pl-8 pr-4">
          <slot name="head">
            <div class="flex flex-col">
              <div class="flex justify-end">
                <button class="pb-2 leading-none">
                  <div class="text-xl h-3">
                    <span autofocus class="font-extralight hover:font-extrabold" @click="emitCancel"
                      ><CloseIcon :width="20"
                    /></span>
                  </div>
                </button>
              </div>
              <h3 class="text-2xl font-semibold">
                <slot name="title" />
              </h3>
            </div>
          </slot>
        </div>
        <div class="pl-8 pr-8">
          <p class="modal-body text-sm">
            <slot name="body" />
          </p>
        </div>
      </div>
    </div>
    <div class="opacity-60 bg-black fixed inset-0 z-40 bk-black" />
  </div>
</template>

<script lang="ts">
import CloseIcon from '../icons/CloseIcon.vue';

export default {
  name: 'DiceModal',
  components: { CloseIcon },
  props: {
    isVisible: {
      type: Boolean
    }
  },
  created() {
    const that = this;

    document.addEventListener('keyup', function (e) {
      if (e.key === 'Escape') {
        that.emitCancel();
      }
    });
  },
  beforeMount() {
    window.addEventListener('cancel', (e) => {
      this.$emit('cancel');
    });
  },
  methods: {
    emitCancel() {
      this.$emit('cancel');
    },
    emitConfirm() {
      this.$emit('confirm');
    }
  }
};
</script>
