<template>
  <MFALayout class="wrapper">
    <DiceToast :is-visible="mfaStore.toast.show" :toast-type="mfaStore.toast.type">
      <template #toastBody>
        {{ mfaStore.toast.text }}
        <span v-if="mfaStore.maximumCodeValidationAttemptsMet">
          <button type="button" class="dice-link" @click.prevent="resendCode">
            Please request a new code.
          </button>
          <br />
        </span>
        <span v-if="mfaStore.toast.type === 'error'" class="toast-support-link" style="white-space: nowrap">
          If you need help,
          <a :href="`${baseDomain}/about/contact-us`" class="contact-support self-end dice-link">
            Contact Support</a>.
        </span>
        <span v-if="mfaStore.smsFailure">
          <a
            :href="`mailto:${mfaStore.deliveryTarget}`"
            class="contact-support self-end dice-link"
            >{{ mfaStore.deliveryTarget }}</a
          >.
        </span>
      </template>
    </DiceToast>

    <Card>
      <div>
        <div class="pb-6">
          <h1 class="dice-text-100 text-center pb-2">Enter your one time code</h1>
        </div>
        <div class="pb-12">
          <p v-if="!mfaStore.smsFailure" class="text-dice-gray-750">
            <p>We sent a code to {{ mfaStore.deliveryTarget }}.</p>
            <br>
            <p>If you do not receive a code after 3 minutes, please request a new code or Cancel and try an alternate MFA method.</p>
          </p>
          <p v-if="mfaStore.smsFailure" class="text-dice-gray-750">
            The delivery attempt to {{ mfaStore.selectedPhoneNumber }} was unsuccessful. We have sent
            your one time code to {{ mfaStore.deliveryTarget }}. If you have any issues, please
            <a :href="`${baseDomain}/about/contact-us`" class="contact-support self-end dice-link">
              Contact Support</a>.
          </p>
        </div>
        <form name="challenge-code" autocomplete="off" @submit.prevent="validateChallengeCode">
          <label class="dice-label mb-1" for="challenge-code" data-cy="emailLabel">
            Code <span class="text-red-500">*</span>
          </label>
          <div class="relative">
            <input
              id="challenge-code"
              v-model="challengeCode"
              type="text"
              name="code"
              :class="
                mfaStore.showChallengeCodeError || inputDisabled ? 'dice-input error' : 'dice-input'
              "
              autofocus
              data-cy="codeInput"
              inputmode="numeric"
              pattern="[0-9]*"
              :disabled="inputDisabled"
            />
          </div>
          <div class="flex flex-col">
            <button
              type="button"
              :class="
                mfaStore.resendDisabled
                  ? 'dice-link text-sm mt-2 mb-4 flex justify-end text-dice-gray-500 disabled'
                  : 'dice-link text-sm mt-2 mb-4 flex justify-end'
              "
              @click.prevent="resendCode"
              :disabled="mfaStore.resendDisabled"
            >
              Resend Code
            </button>
          </div>

          <div class="flex flex-col mt-6 pb-12 space-y-4">
            <button
              type="submit"
              data-cy="nextBtn"
              class="bg-dice-blue-500 hover:bg-dice-blue-600 text-white rounded text-center px-5 py-3 w-full font-normal rounded-full"
            >
              Next
              <span
                v-if="mfaStore.loading"
                class="spinner-border animate-spin inline-block w-4 h-4 border-1 rounded-full"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </span>
            </button>
            <DiceButton class="rounded-full"
              data-cy="cancelBtn"
              btn-text="Cancel"
              btn-type="secondary"
              click-handler="RESET_MFA_CHANNEL"
            />
          </div>
          <hr>
          <div class="grid grid-rows-2 md:gap-10 pt-12 pb-0">
            <div>
              <p class="dice-text text-xl pb-2" data-cy="candidateLoginHeader">
                Are you having trouble with your one time code?
              </p>
              <a :href="`${baseDomain}/about/contact-us`" class="dice-link pb-3 pt-3" data-cy="candidateLoginLink">Contact Support</a>
            </div>
          </div>
        </form>
      </div>
    </Card>
  </MFALayout>
</template>

<script lang="ts">
import consola from 'consola';
import DiceToast from '../../components/core/DiceToast/DiceToast.vue';
import DiceButton from '../../components/core/diceButton/DiceButton.vue';
import CallIcon from '../../components/core/icons/CallIcon.vue';
import {
  gaDataLayerPush,
  GtmEvent,
  GtmEventAction,
  GtmEventLabel,
  GtmEventCategory
} from '../../domain/ga-tracking';
import { truthOf } from '../../lib/boolean.lib';
import { useMfaStore } from '../../stores/mfaStore';
import MFALayout from '../../layouts/MfaLayout.vue';
import Card from '../../components/Card.vue'

export default {
  name: 'ChallengeResponse',
  components: {
    DiceButton,
    DiceToast,
    CallIcon,
    MFALayout,
    Card
  },
  layout: 'MfaLayout',
  setup() {
    const mfaStore = useMfaStore();
    const baseDomain = process.env.BASE_DOMAIN
    return { mfaStore, baseDomain };
  },
  data():{ challengeCode: string | null, isVisible: boolean, inputDisabled: boolean } {
    return {
      challengeCode: null,
      isVisible: false,
      inputDisabled: false
    };
  },
  head() {
    return {
      title: '| MFA Challenge Response'
    };
  },
  mounted() {
    window.removeEventListener('keypress', this.implicitSubmitHandler); //TODO: Check if this was ever working
    window.addEventListener('keypress', this.implicitChallengeCodeValidateHandler);
    if (this.mfaStore.userOptedIn) {
      // TODO: This is not showing up sometimes
      gaDataLayerPush(
        GtmEvent.LoginEvent,
        truthOf(this.mfaStore.mfaComplete) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
        GtmEventAction.EnterCode,
        GtmEventLabel.CodeDisplayed
      );
    }
    const challengeCodeEle = document.querySelector<HTMLInputElement>('#challenge-code');
    if (challengeCodeEle) {
      challengeCodeEle.focus();
    }
    setTimeout(() => {
      this.mfaStore.resendDisabled = false;
    }, 180000); // 3 minutes
  },
  methods: {
    implicitChallengeCodeValidateHandler(e: KeyboardEvent) {
      if (
        e.key === 'Enter' &&
        // Required for typesafety on e.target.value
        e.target &&
        'value' in e.target &&
        typeof e.target.value === 'string'
      ) {
        e.preventDefault();
        const code = e.target.value.trim();
        this.mfaStore.VALIDATE_CHALLENGE_CODE(code);
      }
    },
    async resendCode() {
      if (!this.mfaStore.loading && !this.mfaStore.resendDisabled) {
        // disable input here
        try {
          this.mfaStore.resendDisabled = true; // disable the resend button
          this.inputDisabled = true;
          await this.mfaStore.SEND_CHALLENGE_CODE('codeResend');
          // Enable the resend button after 3 minutes
          setTimeout(() => {
            this.mfaStore.resendDisabled = false;
          }, 180000);
        } catch (error) {
          consola.log(error);
        } finally {
          this.inputDisabled = false;
        }
        this.challengeCode = '';
        const challengeCodeEle = document.querySelector<HTMLInputElement>('#challenge-code');
        if (challengeCodeEle) {
          challengeCodeEle.focus();
        }
      }
    },
    async validateChallengeCode() {
      try {
        await this.mfaStore.VALIDATE_CHALLENGE_CODE(this.challengeCode?.trim());
      } catch (error) {
        console.log(error);
      } finally {
        if (this.mfaStore.maximumCodeValidationAttemptsMet) {
          this.inputDisabled = true;
        }
      }
    }
  }
};
</script>
<style>
.error {
  border-color: #952827;
}

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  border-color: #19557d;
  content: '';
  display: inline-block;
  height: 0.65em;
  left: 0.15em;
  position: relative;
  top: 0.45em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.65em;
}

.chevron.left:before {
  left: 0.05em;
  transform: rotate(-135deg);
}

.disabled {
  color: #999999;
}
</style>
