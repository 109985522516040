import { Component, ComputedOptions, MethodOptions, createApp, provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import getApolloClient from '../lib/apollo.client';
import App from './app.vue';
import router from './router';

const bootstrap = (
  AppElement: Component<any, any, any, ComputedOptions, MethodOptions>,
  selector: string
) => {
  const pinia = createPinia();

  const app = createApp(AppElement);
  // app.config.compilerOptions.isCustomElement = (tag => tag.startsWith('dhi-seds-'));
  app.use(router)
  app.use(pinia);
  
  const apolloClient = getApolloClient();
  provide(DefaultApolloClient, apolloClient);

  app.mount(selector);
  return app;
};


bootstrap(App, '#app');

export default bootstrap