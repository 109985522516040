<template>
  <MfaLayout>
    <section>
      <Card>
        <div>
          <DiceTitle title-text="Select your email address" data-cy="emailConfirmationTitle" />
          <p class="text-dice-gray-750 max-w-md mb-8 text-center" data-cy="pageText">
            Message and data rates may apply.
          </p>
          <div class="mb-1">
            <DiceRadioContainer :choices="[mfaStore.email]" data-cy="emailRadioContainer" />
          </div>
          <div class="flex justify-center">
            <RememberMe data-cy="rememberMe" class="mb-8" @checked="rememberMeAction" />
          </div>
          <div class="flex flex-col space-y-2 items-center pb-12">
            <DiceButton class="rounded-full"
              btn-type="primary"
              btn-text="Send Code"
              click-handler="SET_MFA_CHANNEL"
              :form-data="{ email: mfaStore.email, rememberMeChecked }"
              :show-loader="true"
              data-cy="sendCodeButton"
            />
            <button class="cancel-btn text-dice-blue-500 border border-dice-blue-500 rounded px-5 py-3 text-center w-full rounded-full" @click="confirmEmailCancel">
              Cancel
            </button>
            </div>
            <hr>
            <div class="grid grid-rows-2 md:gap-10 pt-12 pb-0">
              <div>
                <p class="dice-text text-xl pb-2" data-cy="candidateLoginHeader">
                  Are you having trouble sending the code?
                </p>
                <a :href="`${baseDomain}/about/contact-us`" class="dice-link pb-3 pt-3" data-cy="candidateLoginLink">Contact Support</a>
              </div>
            </div>
          </div>
      </Card>
    </section>
  </MfaLayout>
</template>

<script lang="ts">
import DiceButton from '../../components/core/diceButton/DiceButton.vue'
import DiceTitle from '../../components/core/DiceTitle/index.vue'
import DiceRadioContainer from '../../components/core/DiceRadioBtns/index.vue'
import RememberMe from '../../components/auth/RememberMe.vue'
import { gaDataLayerPush, GtmEvent, GtmEventAction, GtmEventCategory, GtmEventLabel } from '../../domain/ga-tracking'
import { truthOf } from '../../lib/boolean.lib'
import { useMfaStore } from '../../stores/mfaStore'
import MfaLayout from '../../layouts/MfaLayout.vue'
import Card from '../../components/Card.vue'

export default {
  name: 'ConfirmEmail',
  components: { DiceRadioContainer, DiceTitle, DiceButton, RememberMe, MfaLayout, Card },
  layout: 'MfaLayout',
  setup() {
    const mfaStore = useMfaStore()
    const baseDomain = process.env.BASE_DOMAIN
    return { mfaStore, baseDomain }
  },
  data () {
    return {
      rememberMeChecked: false
    }
  },
  head () {
    return {
      title: '| Confirm Email'
    }
  },
  created () {
    window.addEventListener('keypress', this.implicitSubmitHandler)
  },
  beforeUnmount () {
    window.removeEventListener('keypress', this.implicitSubmitHandler)
  },
  methods: {
    implicitSubmitHandler: function (e: KeyboardEvent) {
      if (e.key === 'Enter') {
        this.mfaStore.SET_MFA_CHANNEL({
            email: this.mfaStore.email || 'example.dice.com',
            rememberMeChecked: this.rememberMeChecked
          })
      }
    },
    rememberMeAction: function (checked: boolean) {
      this.rememberMeChecked = checked
    },
    confirmEmailCancel: function () {
      gaDataLayerPush(
        GtmEvent.LoginEvent,
        truthOf(this.mfaStore.mfaComplete) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
        GtmEventAction.SignUpSend,
        GtmEventLabel.Cancel
      )
      this.$router.push('/employer/mfa-method')
    }
  }
}
</script>

<style scoped>

</style>
