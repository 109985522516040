<template>
  <div class="icon">
    <MinusCircle v-if="!pass && pass !== null" />
    <CheckCircle :pass="pass" v-if="pass || pass === null" />
  </div>
</template>

<script lang="ts">
import CheckCircle from '../../core/icons/CheckCircle.vue';
import MinusCircle from '../../core/icons/MinusCircle.vue';

export default {
  name: 'CheckIcon',
  components: {
    CheckCircle,
    MinusCircle
  },
  props: {
    pass: { type: Boolean }
  }
};
</script>
