<template>
  <div
    v-if="isVisible"
    :class="`${toastType} flex flex-row toast-wrapper sm:w-full md:w-1/2 lg:w-2/5 px-5 py-6`"
  >
    <div class="toast-body w-11/12">
      <slot name="toastBody" />
    </div>
    <div
      class="w-1/12 flex justify-end font-extralight hover:font-extrabold text-right"
      @click="hideToast"
    >
      <CloseIcon :width="20" />
    </div>
  </div>
</template>
<script lang="ts">
import { useMfaStore } from '../../../stores/mfaStore';
import CloseIcon from '../icons/CloseIcon.vue';
export default {
  name: 'DiceToast',
  components: {
    CloseIcon
  },
  setup(){
    const mfaStore = useMfaStore()
    return { mfaStore }
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    toastType: {
      type: String,
      required: true,
      default: 'success'
    }
  },
  methods: {
    hideToast() {
      this.mfaStore.HIDE_TOAST()
    }
  }
};
</script>

<style scoped>
.toast-wrapper {
  position: absolute;
  top: 40px;
  right: 20px;
  border-radius: 3px;
}

@media screen and (max-width: 1024px) {
  .toast-wrapper {
    margin-left: 1.5rem;
  }
}

.success {
  background-color: #eaf0eb;
}

.error {
  background-color: #f9ebeb;
}
</style>
