<template>
  <div>
    <dhi-seds-nav-footer v-if="getUserType() === 'candidate'"></dhi-seds-nav-footer>
    <dhi-seds-nav-footer-employer
      v-if="getUserType() === 'employer'"
    ></dhi-seds-nav-footer-employer>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FooterWebComponent',
  methods: {
    getUserType: function () {
      return /account/.test(window.location.pathname) ? 'candidate' : 'employer';
    }
  }
};
</script>
