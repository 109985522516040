<template>
  <div>
    <input
      type="radio"
      :id="id"
      :name="selectionName"
      :value="text"
      :checked="checked"
      @click="selected()"
    />
    <label :for="id" class="pl-2">{{ text }}</label>
  </div>
</template>

<script lang="ts">
export default {
  name: 'DiceRadioInput',
  props: {
    id: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    },
    selectionName: {
      type: String,
      required: true
    }
  },
  methods: {
    selected: function () {
      this.$emit('selected', this.$props.text);
    }
  }
};
</script>
