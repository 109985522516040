<script lang="ts" setup>
// Queries & Mutations
import consola from 'consola'
import DiceModal from '../../components/core/DiceModal/DiceModal.vue';
import CallIcon from '../../components/core/icons/CallIcon.vue';
import { useTokenStore } from '../../stores/tokenStore';
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useMfaStore } from '../../stores/mfaStore'
import {clearTokenCookies, clearTokenLocalStorage, deleteAllCookies, getAccessTokenStatus} from '../../domain/token'
import {getEnvironment, getQueryParams, redirect} from '../../lib/url.lib'
import getApolloClient from '../../lib/apollo.client'
import { SetMfaChanneResult, SetMfaChanneInput, setMfaChannelMutation } from '../../domain/auth-api/mutations/mfa-channel'
import isEmailFmt from '../../lib/email.lib';
import { GtmEvent, GtmEventAction, GtmEventCategory, GtmEventLabel, gaDataLayerPush } from '../../domain/ga-tracking';
import SsoLayout from '../../layouts/SsoLayout.vue';
import Card from '../../components/Card.vue'
import {
  CheckSsoStatusInput,
  checkSsoStatusQuery,
  CheckSsoStatusResult
} from "../../domain/auth-api/queries/check-sso-status";
import { useRouter } from 'vue-router'

const baseDomain = process.env.BASE_DOMAIN;
const router = useRouter()

const email = ref('')
const status = ref('')
const processingSignIn = ref(false)
const isVisible = ref(false)

const mfaStore = useMfaStore()
const tokenStore = useTokenStore()

onBeforeMount(() => {
  const accessToken = tokenStore.token;
  switch(getAccessTokenStatus(accessToken)){
    case 'freshAccessToken':
      redirectToLanding()
      break
    case 'expiredAccessToken':
      status.value = 'Your session has expired. Please log in again.'
        if (getEnvironment() === 'prod' || getEnvironment() === 'pilot') {
        clearTokenCookies()
      }
      clearTokenLocalStorage()
      break
    case 'noAccessToken':
    case 'candidateAccessToken':
    default:
  }
})

onMounted(() => {
  mfaStore.SAVE_QUERY_PARAMS(getQueryParams())
})

/** Computed */
const isUserLocked = computed(() =>
    /Your account is locked\. Please Contact Support\./i.test(status.value)
);
const isFormInputValid = computed(
  () => email.value.length > 1 && isEmailFmt(email.value)
);


/** Actions */
const redirectToLanding = () => redirect(`${baseDomain}/employer/login-landing`, mfaStore.queryParams);

const redirectToPassword = () => {
  saveUserInputs(null)
  router.push(`/employer/enter-password`)
}

const redirectToSso = (link: string) => {
  saveUserInputs(link)
  router.push(`/employer/sso-sign-in`)
}

const saveUserInputs = (link: string | null) => {
  mfaStore.SAVE_EMAIL(email.value.trim())
  if (link) {
    mfaStore.SAVE_SSO_LINK(link)
  }
}

const openModal = () => {
  isVisible.value = true
}

const closeModal = () => {
  isVisible.value = false
}

const submit = async () => {
  if (processingSignIn.value) { return }
  if (!isFormInputValid.value) {
    status.value = 'Please enter a username to sign in'
    return
  }
  if (getEnvironment() === 'prod' || getEnvironment() === 'pilot') {
    clearTokenCookies() // removed cookies immediately before log in to ensure fresh
    deleteAllCookies() // remove all cookies to ensure technologist cookies are removed before signing in
  }
  clearTokenLocalStorage() // removed local storage immediately before log in to ensure fresh

  try {
    processingSignIn.value = true
    await getApolloClient().mutate<SetMfaChanneResult, SetMfaChanneInput>({
      mutation: setMfaChannelMutation,
      variables: {
        email: email.value.trim(),
        channelType: ''
      },
      fetchPolicy: 'no-cache'
    })
    const checkSsoStatusResult = await getApolloClient().query<CheckSsoStatusResult, CheckSsoStatusInput>({
      query: checkSsoStatusQuery,
      variables: {
        email: email.value.trim(),
      },
      fetchPolicy: 'no-cache'
    })

    const { data: { checkSsoStatus } } = checkSsoStatusResult
    const { status: statusMessage, ssoStatus: {enabled, link} } = checkSsoStatus

    if (statusMessage === 'SUCCESS') {
      if (!enabled) {
        redirectToPassword()
      } else if (enabled && link) {
        redirectToSso(link)
      }
    } else {
      status.value = checkSsoStatus.status
      gaDataLayerPush(
        GtmEvent.LoginEvent,
        GtmEventCategory.Kpi,
        GtmEventAction.Login,
        GtmEventLabel.Failure
      )
    }
  } catch (ex) {
    consola.error('Error:', ex)
    status.value = 'Unexpected sign in response'
  } finally {
    processingSignIn.value = false
  }
}
</script>

<template>
  <SsoLayout class="flex">
    <DiceModal data-cy="contactSupportModal" :is-visible="isVisible" @cancel="closeModal" @confirm="closeModal">
      <template #title>
        Contact Support
      </template>
      <template #body>
        <div class="flex mb-10">
          <div class="flex-row">
            <div class="flex items-start m-auto">
              <CallIcon color="dice-blue-400" :width="20" />
              <div class="pl-1">
                <span id="phoneText" class="dice-text-500 dice-link">888-321-DICE</span><span
                  id="diceDigits"
                  class="dice-text-500"
                >(3423)</span>
              </div>
            </div>
            <p id="hoursOperation" class="dice-text-500">
              Monday - Friday, 8am to 5pm CST
            </p>
            <div class="flex items-center m-auto pt-4">
              <img src="../../assets/icons/mail.svg" alt="mail" class="w-20px">
              <a href="mailto:support@dice.com" role="link" class="pl-1 dice-link">support@dice.com</a>
            </div>
          </div>
        </div>
      </template>
      <template #footer />
    </DiceModal>

    <Card>
        <h1 id="signin" title="sign-in" class="dice-text-100 text-center pb-2" data-cy="signInHeader">
          Welcome Employers
        </h1>
        <h2 id="login" class="text-xl text-center font-normal break-words pb-12">
          Log in to continue
        </h2>
        <form name="login" autocomplete="on" @submit.prevent="submit">
          <label class="dice-label mb-1" for="email" data-cy="emailLabel">
            Email Address <span class="text-red-500">*</span>
          </label>
          <div class="relative">
            <input
              id="email"
              v-model="email"
              type="email"
              name="email"
              class="dice-input"
              autofocus
              autocomplete="email"
              data-cy="emailInput"
            >
          </div>

          <div id="errorText" class="h-9" aria-live="polite">
            <p v-if="status.length > 1 && status !== 'SUCCESS' && !isUserLocked" class="py-2 text-sm font-light text-dice-red-500" data-cy="errorText">
              {{ status }}
            </p>
            <p v-else-if="isUserLocked" class="py-2 text-sm font-light text-dice-red-500" data-cy="errorText">
              Your account is locked. Please <button class="contact-support self-end dice-link" @click.prevent="openModal" data-cy="contactSupport">Contact Support</button>.
            </p>
          </div>

          <button role="button" class="dice-btn py-3 mt-6 w-full rounded-full" data-cy="signInButton" type="submit">
            Continue
            <span v-if="processingSignIn === true" class="spinner-border animate-spin inline-block w-4 h-4 border-1 rounded-full" role="status">
              <span class="visually-hidden">Loading...</span>
            </span>
          </button>
          <div class="text-center pb-12">
            <a
              :href="`${baseDomain}/employer/forgotten-password`"
              class="dice-link text-sm inline-block mt-7 mb-3"
              data-cy="forgotPasswordLink"
              role="link"
            >
              <p>Forgot password?</p>
            </a>
          </div>
          <hr>
          <div class="grid grid-rows-2 mt-6 md:gap-10 pt-12">
            <div>
              <p class="dice-text text-xl pb-2" data-cy="candidateLoginHeader">
                Partner with us to help you hire top tech talent.
              </p>
              <a :href="`${baseDomain}/hiring`" class="dice-link pb-3 pt-3" data-cy="candidateLoginLink">Contact Dice</a>
            </div>
            <div>
              <p class="dice-text text-xl pt-4 pb-1 md:pt-0" data-cy="hiringRedirectHeader">
                Are you searching for a job in tech?
              </p>
              <a :href="`${baseDomain}/dashboard/login`" class="dice-link hiring-link pb-3 pt-3" data-cy="hiringRedirectLink">Register Now</a>
            </div>
          </div>
        </form>
    </Card>
  </SsoLayout>
</template>
