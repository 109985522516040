<template>
  <div>
    <DiceRadioInput
      v-for="(choice, index) in choices"
      :id="'choice-' + (index + 1)"
      :key="index"
      class="border rounded-sm p-2 mb-2.5 text-slate-700"
      selection-name="choices"
      :checked="selectedInput ? selectedInput === choice : index === 0"
      :text="choice"
      @selected="emitSelection"
    />
  </div>
</template>

<script lang="ts">
import DiceRadioInput from '../DiceRadioBtns/DiceRadioInput.vue';

export default {
  name: 'DiceRadioContainer',
  components: { DiceRadioInput },
  props: {
    choices: {
      type: Array[String],
      required: true
    },
    selectedInput: {
      type: String,
      required: false
    }
  },
  methods: {
    emitSelection: function (selection: unknown) {
      this.$emit('selection', selection); //TODO: fixme
    }
  }
};
</script>
