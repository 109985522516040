<template>
  <div class="flex-col">
    <h1 class="dice-text-100 text-center">Reset your password</h1>
    <p class="text-center">Your password has been reset successfully. Please sign in with your new password.</p>
    <button
      role="button"
      class="dice-btn py-3 mt-12 font-thin w-full rounded-full"
      type="button"
      @click="resetPassword"
    >
      Sign In
    </button>
  </div>
</template>

<script lang="ts">
import { redirect } from '../../lib/url.lib';

export default {
  name: 'ResetPasswordSuccess',
  props: {
    path: {
      type: String,
      require: true,
      default: ''
    }
  },
  created() {
    // eslint-disable-next-line nuxt/no-globals-in-created
    window.addEventListener('keypress', this.implicitSubmitHandler);
  },
  deactivated() {
    window.removeEventListener('keypress', this.implicitSubmitHandler);
  },
  methods: {
    resetPassword() {
      const isCandidate = /account/.test(window.location.pathname);
      const baseDomain = process.env.BASE_DOMAIN;
      if (isCandidate) {
        redirect(`${baseDomain}${this.$props.path}`);
      } else {
        this.$router.push(this.$props.path)
      }
    },

    /**
     * TODO: we shouldnt need this if we are listening to form submit event.
     *
     * Handle the implicit form submit requirement for submitting the login
     * by checking if the enter key was pressed. This method will submit the form
     * even when the form in not in focus.
     * @param {KeyboardEvent} e
     */
    implicitSubmitHandler(e: KeyboardEvent) {
      if (e.key === 'Enter') {
        this.resetPassword();
      }
    }
  }
};
</script>
