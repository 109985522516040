<template>
  <div class="flex flex-col justify-between bg-gray-100">
    <div id="upper" class="flex bg-black h-14 justify-center">
      <div class="flex bg-black items-center pl-2 h-14 w-full lg:w-5/6">
        <svg width="100" height="30" viewBox="0 0 341 183">
          <title>Dice Logo</title>
          <path
            d="M320.7 0H49.8C38.7 0 29.7 9 29.7 20.1v124.5c0 18.2-12.7 33.5-29.7 37.6h320.7c11.1 0 20.1-9 20.1-20.1v-142C340.9 9 331.8 0 320.7 0z"
            fill="#CC2128"
          />
          <g fill="#FFF">
            <path
              d="M121.2 58.7h12.5c8 0 13.4.4 16.1 1.1 2.7.7 4.6 1.9 5.7 3.6 1.1 1.7 1.6 3.6 1.6 5.6-.1 2.1-.8 6.1-2 12.2l-4.8 22.4c-1.2 5.8-2.3 9.6-3.2 11.5-1 1.9-2.2 3.5-3.8 4.6-1.6 1.1-3.4 1.8-5.5 2.3-2.1.4-5.1.6-9.1.6h-21l13.5-63.9zm14.3 10.9l-8.9 42.1c2.5 0 4.1-.5 4.8-1.4.8-1 1.6-3.6 2.5-7.8l5.3-24.9c.6-2.9.9-4.8.9-5.6 0-.8-.3-1.4-.9-1.8-.6-.4-1.8-.6-3.7-.6zM179.6 70.2l-11.1 52.5H152l11.1-52.5h16.5zm2.4-11.5l-1.8 8.3h-16.5l1.8-8.3H182zM216.3 90h-15.1l1.3-6c.4-2.1.6-3.5.4-4.3-.2-.7-.8-1.1-1.7-1.1-.9 0-1.6.3-2.1 1s-.9 2.1-1.4 4.4l-5.2 24.7c-.4 1.8-.5 3.2-.2 4.2.3.9.9 1.4 1.8 1.4 1.1 0 1.9-.5 2.5-1.5.6-1 1.2-2.8 1.8-5.5l1.3-6.2H214c-.9 4.2-1.7 7.3-2.5 9.4-.7 2.1-2.1 4.2-4 6.4-2 2.2-4.3 3.9-6.9 5-2.6 1.1-5.7 1.7-9.2 1.7-4.5 0-7.9-.8-10.2-2.3-2.3-1.5-3.7-3.7-4.2-6.5s-.2-6.7.9-11.8l3.2-14.9c1-4.5 2.1-7.8 3.5-10.1 1.4-2.2 3.7-4.3 7-6.1 3.3-1.8 7-2.7 11.2-2.7 4.2 0 7.5.9 10.1 2.6 2.6 1.8 4.1 4 4.5 6.7.3 2.8 0 6.6-1.1 11.5zM255.8 97.4h-20.7l-2.4 11.3c-.5 2.4-.6 3.9-.5 4.6.2.7.8 1 1.8 1 1.2 0 2.1-.4 2.7-1.4.6-.9 1.2-2.7 1.7-5.2l1.5-6.9h15.2l-.8 3.9c-.7 3.2-1.4 5.7-2.2 7.5-.8 1.7-2.1 3.6-4 5.6-1.9 2-4.2 3.4-6.7 4.4-2.5 1-5.5 1.5-9 1.5-3.4 0-6.3-.5-8.6-1.5-2.4-1-4.1-2.3-5.2-4-1.1-1.7-1.7-3.6-1.8-5.6-.1-2 .2-5 1.1-8.9l3.3-15.3c1-4.6 2.4-8.2 4.1-10.8 1.8-2.7 4.3-4.7 7.4-6.1 3.1-1.4 6.5-2.1 10.2-2.1 4.5 0 8 .9 10.6 2.6 2.6 1.7 4.1 3.9 4.7 6.8.6 2.8.3 6.7-.7 11.8l-1.7 6.8zm-14.1-8.5l.8-3.8c.6-2.7.8-4.4.7-5.3-.1-.8-.6-1.2-1.6-1.2-1.1 0-1.9.3-2.3 1-.4.7-.9 2.5-1.5 5.4l-.8 3.8h4.7v.1z"
            />
          </g>
          <g fill="#FFF" opacity=".75">
            <path
              d="M264.579 58.57c-1.438 1.445-2.157 3.19-2.157 5.237 0 2.063.715 3.82 2.145 5.274 1.437 1.453 3.183 2.18 5.238 2.18 2.055 0 3.8-.727 5.238-2.18 1.438-1.453 2.157-3.211 2.157-5.274 0-2.046-.72-3.792-2.157-5.238-1.445-1.453-3.191-2.18-5.238-2.18-2.039 0-3.781.727-5.226 2.18zm11.32 11.343c-1.68 1.687-3.711 2.531-6.094 2.531-2.383 0-4.41-.844-6.082-2.531-1.664-1.68-2.496-3.715-2.496-6.106 0-2.367.84-4.39 2.52-6.07 1.671-1.672 3.69-2.508 6.058-2.508 2.383 0 4.414.836 6.094 2.508 1.68 1.672 2.52 3.695 2.52 6.07 0 2.383-.84 4.418-2.52 6.106zm-4.47-9.363c-.419-.235-1.089-.352-2.01-.352H268v3.422h1.5c.704 0 1.23-.07 1.582-.211.649-.258.973-.75.973-1.477 0-.687-.209-1.148-.627-1.382zm-1.788-1.489c1.172 0 2.031.114 2.578.34.977.406 1.465 1.203 1.465 2.39 0 .845-.309 1.466-.926 1.864-.328.211-.785.363-1.37.457.741.117 1.284.426 1.628.926.344.5.516.988.516 1.465v.691c0 .219.007.453.023.703.016.25.043.414.082.493l.059.117h-1.559l-.023-.094a2.29 2.29 0 01-.024-.106l-.035-.304v-.75c0-1.094-.297-1.817-.89-2.168-.352-.203-.97-.305-1.852-.305H268v3.727h-1.664V59.06h3.305z"
            />
          </g>
        </svg>
      </div>
    </div>
    <main>
        <slot />
    </main>
    <div id="upper" class="flex bg-dice-gray-750 h-[10rem] xl:h-64 justify-center">
      <div class="flex bg-dice-gray-750 pl-4 pt-6 w-full lg:w-5/6">
        <svg width="100" height="50" viewBox="0 0 341 183">
          <title>Dice Logo</title>
          <path
            d="M320.7 0H49.8C38.7 0 29.7 9 29.7 20.1v124.5c0 18.2-12.7 33.5-29.7 37.6h320.7c11.1 0 20.1-9 20.1-20.1v-142C340.9 9 331.8 0 320.7 0z"
            fill="#CC2128"
          />
          <g fill="#FFF">
            <path
              d="M121.2 58.7h12.5c8 0 13.4.4 16.1 1.1 2.7.7 4.6 1.9 5.7 3.6 1.1 1.7 1.6 3.6 1.6 5.6-.1 2.1-.8 6.1-2 12.2l-4.8 22.4c-1.2 5.8-2.3 9.6-3.2 11.5-1 1.9-2.2 3.5-3.8 4.6-1.6 1.1-3.4 1.8-5.5 2.3-2.1.4-5.1.6-9.1.6h-21l13.5-63.9zm14.3 10.9l-8.9 42.1c2.5 0 4.1-.5 4.8-1.4.8-1 1.6-3.6 2.5-7.8l5.3-24.9c.6-2.9.9-4.8.9-5.6 0-.8-.3-1.4-.9-1.8-.6-.4-1.8-.6-3.7-.6zM179.6 70.2l-11.1 52.5H152l11.1-52.5h16.5zm2.4-11.5l-1.8 8.3h-16.5l1.8-8.3H182zM216.3 90h-15.1l1.3-6c.4-2.1.6-3.5.4-4.3-.2-.7-.8-1.1-1.7-1.1-.9 0-1.6.3-2.1 1s-.9 2.1-1.4 4.4l-5.2 24.7c-.4 1.8-.5 3.2-.2 4.2.3.9.9 1.4 1.8 1.4 1.1 0 1.9-.5 2.5-1.5.6-1 1.2-2.8 1.8-5.5l1.3-6.2H214c-.9 4.2-1.7 7.3-2.5 9.4-.7 2.1-2.1 4.2-4 6.4-2 2.2-4.3 3.9-6.9 5-2.6 1.1-5.7 1.7-9.2 1.7-4.5 0-7.9-.8-10.2-2.3-2.3-1.5-3.7-3.7-4.2-6.5s-.2-6.7.9-11.8l3.2-14.9c1-4.5 2.1-7.8 3.5-10.1 1.4-2.2 3.7-4.3 7-6.1 3.3-1.8 7-2.7 11.2-2.7 4.2 0 7.5.9 10.1 2.6 2.6 1.8 4.1 4 4.5 6.7.3 2.8 0 6.6-1.1 11.5zM255.8 97.4h-20.7l-2.4 11.3c-.5 2.4-.6 3.9-.5 4.6.2.7.8 1 1.8 1 1.2 0 2.1-.4 2.7-1.4.6-.9 1.2-2.7 1.7-5.2l1.5-6.9h15.2l-.8 3.9c-.7 3.2-1.4 5.7-2.2 7.5-.8 1.7-2.1 3.6-4 5.6-1.9 2-4.2 3.4-6.7 4.4-2.5 1-5.5 1.5-9 1.5-3.4 0-6.3-.5-8.6-1.5-2.4-1-4.1-2.3-5.2-4-1.1-1.7-1.7-3.6-1.8-5.6-.1-2 .2-5 1.1-8.9l3.3-15.3c1-4.6 2.4-8.2 4.1-10.8 1.8-2.7 4.3-4.7 7.4-6.1 3.1-1.4 6.5-2.1 10.2-2.1 4.5 0 8 .9 10.6 2.6 2.6 1.7 4.1 3.9 4.7 6.8.6 2.8.3 6.7-.7 11.8l-1.7 6.8zm-14.1-8.5l.8-3.8c.6-2.7.8-4.4.7-5.3-.1-.8-.6-1.2-1.6-1.2-1.1 0-1.9.3-2.3 1-.4.7-.9 2.5-1.5 5.4l-.8 3.8h4.7v.1z"
            />
          </g>
          <g fill="#FFF" opacity=".75">
            <path
              d="M264.579 58.57c-1.438 1.445-2.157 3.19-2.157 5.237 0 2.063.715 3.82 2.145 5.274 1.437 1.453 3.183 2.18 5.238 2.18 2.055 0 3.8-.727 5.238-2.18 1.438-1.453 2.157-3.211 2.157-5.274 0-2.046-.72-3.792-2.157-5.238-1.445-1.453-3.191-2.18-5.238-2.18-2.039 0-3.781.727-5.226 2.18zm11.32 11.343c-1.68 1.687-3.711 2.531-6.094 2.531-2.383 0-4.41-.844-6.082-2.531-1.664-1.68-2.496-3.715-2.496-6.106 0-2.367.84-4.39 2.52-6.07 1.671-1.672 3.69-2.508 6.058-2.508 2.383 0 4.414.836 6.094 2.508 1.68 1.672 2.52 3.695 2.52 6.07 0 2.383-.84 4.418-2.52 6.106zm-4.47-9.363c-.419-.235-1.089-.352-2.01-.352H268v3.422h1.5c.704 0 1.23-.07 1.582-.211.649-.258.973-.75.973-1.477 0-.687-.209-1.148-.627-1.382zm-1.788-1.489c1.172 0 2.031.114 2.578.34.977.406 1.465 1.203 1.465 2.39 0 .845-.309 1.466-.926 1.864-.328.211-.785.363-1.37.457.741.117 1.284.426 1.628.926.344.5.516.988.516 1.465v.691c0 .219.007.453.023.703.016.25.043.414.082.493l.059.117h-1.559l-.023-.094a2.29 2.29 0 01-.024-.106l-.035-.304v-.75c0-1.094-.297-1.817-.89-2.168-.352-.203-.97-.305-1.852-.305H268v3.727h-1.664V59.06h3.305z"
            />
          </g>
        </svg>
      </div>
    </div>
    <div class="flex h-20 bg-black" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'MfaLayout'
};
</script>

<style scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mfa-content {
  position: relative;
}
</style>
