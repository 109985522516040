import gql from 'graphql-tag';
import { z } from 'zod';

export const setMfaChannelMutation = gql`
  mutation ($email: String!, $channelType: String!, $phoneNumber: String) {
    setMfaChannel(input: { email: $email, channelType: $channelType, phoneNumber: $phoneNumber }) {
      status
    }
  }
`;
export const SetMfaChanneInputSchema = z.object({
  email: z.string().email(),
  channelType: z.string(),
  phoneNumber: z.string().optional()
});

export const SetMfaChanneResultSchema = z.object({
  setMfaChannel: z.object({
    status: z.string()
  })
});

export const MfaChannelSchema = z.object({
  channelType: z.union([z.literal('email'), z.literal('sms')]),
  primary: z.boolean().nullish(),
  channelDescription: z.string()
});

export type SetMfaChanneInput = z.infer<typeof SetMfaChanneInputSchema>;
export type SetMfaChanneResult = z.infer<typeof SetMfaChanneResultSchema>;
export type MfaChannel = z.infer<typeof MfaChannelSchema>;
