<template>
  <SsoLayout class="flex">
    <Card>
      <h1 id="signin" title="sign-in" class="dice-text-100 text-center pb-2" data-cy="signInHeader">
        SSO Sign In
      </h1>
      <h2 id="login" class="text-xl text-center font-normal break-words pb-12">
        <p>There is no Dice account associated</p>
        <p>with your user information.</p>
      </h2>
      <hr>
      <div>
        <p class="dice-text text-xl pb-2 pt-12 text-center">
          Please reach out to our Support team.
        </p>
        <a :href="`${baseDomain}/hiring`" class="dice-link pb-3 pt-3 block mx-auto w-max">Contact Dice Support</a>
      </div>
    </Card>
  </SsoLayout>
</template>

<script lang="ts">

import SsoLayout from '../../layouts/SsoLayout.vue';
import Card from '../../components/Card.vue'
export default {
  name: 'Sso',
  components: { SsoLayout, Card }
};
</script>
