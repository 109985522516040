<template>
  <MFALayout>
    <Card>
      <div>
        <DiceTitle class="text-center pb-2" title-text="How would you like to verify your account?" />
        <div class="pb-4">
          <p class="text-dice-gray-750">
            Dice will send you a one-time code via one of the contact options listed on your account.
          </p>
        </div>
        <DiceRadioContainer :choices="mfaStore.mfaChannelOptions" :selected-input="mfaStore.defaultMfaMethod" @selection="mfaSelection" />
        <div class="flex flex-col mt-12 space-y-2">
          <button class="bg-dice-blue-500 hover:bg-dice-blue-600 text-white rounded rounded-full text-center px-5 py-3 w-full font-thin"
            data-cy="nextBtn"
            @click="mfaSelectionRedirect()">
            Next
          </button>
          <button class="cancel-btn text-dice-blue-500 border border-dice-blue-500 rounded rounded-full px-5 py-3 text-center w-full" @click="mfaMethodCancel">
            Cancel
          </button>
        </div>
      </div>
    </Card>
  </MFALayout>
</template>

<script lang="ts">
import DiceRadioContainer from '../../components/core/DiceRadioBtns/index.vue'
import DiceTitle from '../../components/core/DiceTitle/index.vue'
import { gaDataLayerPush, GtmEvent, GtmEventAction, GtmEventCategory, GtmEventLabel } from '../../domain/ga-tracking'
import { truthOf } from '../../lib/boolean.lib'
import { useMfaStore } from '../../stores/mfaStore'
import MFALayout from '../../layouts/MfaLayout.vue';
import Card from '../../components/Card.vue'
export default {
  name: 'MfaMethod',
  components: {
    DiceRadioContainer,
    DiceTitle,
    MFALayout,
    Card
  },
  layout: 'MfaLayout',
  setup() {
    const mfaStore = useMfaStore()
    return { mfaStore }
  },
  methods: {
    mfaSelection: function (choice: string) {
      this.mfaStore.SAVE_MFA_METHOD(choice)
    },
    mfaSelectionRedirect: function () {
      if (this.mfaStore.defaultMfaMethod === 'Email') {
        gaDataLayerPush(
          GtmEvent.LoginEvent,
          truthOf(this.mfaStore.mfaComplete) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
          GtmEventAction.VerifyOption,
          GtmEventLabel.Email
        )
        this.$router.push('/employer/confirm-email')
      } else if (this.mfaStore.defaultMfaMethod === 'Text Message') {
        gaDataLayerPush(
          GtmEvent.LoginEvent,
          truthOf(this.mfaStore.mfaComplete) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
          GtmEventAction.VerifyOption,
          GtmEventLabel.Text
        )
        this.$router.push('/employer/confirm-phone-number')
      }
    },
    mfaMethodCancel () {
      gaDataLayerPush(
        GtmEvent.LoginEvent,
        truthOf(this.mfaStore.mfaComplete) ? GtmEventCategory.Login : GtmEventCategory.MfaSignup,
        GtmEventAction.VerifyOption,
        GtmEventLabel.Cancel
      )
      this.$router.push('/employer/login')
    }
  }
}
</script>
