<template>
  <div class="">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="width"
      class="ionicon"
      viewBox="0 0 512 512"
    >
      <path
        fill="none"
        :stroke="fill"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        d="M368 368L144 144M368 144L144 368"
      />
    </svg>
  </div>
  <!--  <svg-->
  <!--    :width="width"-->
  <!--    :height="width"-->
  <!--    viewBox="0 0 24 24"-->
  <!--    fill="none"-->
  <!--    xmlns="http://www.w3.org/2000/svg">-->
  <!--    <path-->
  <!--        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"-->
  <!--        :fill="fill"/>-->
  <!--  </svg>-->
</template>

<script lang="ts">
export default {
  name: 'CloseIcon',
  props: {
    fill: {
      type: String,
      default: '#333333',
      required: false
    },
    width: {
      type: Number,
      default: 50,
      required: false
    }
  }
};
</script>
