import Cookies from 'js-cookie';
import { getDomainWithoutSubdomain } from '../lib/url.lib';
import base64url from 'base64url';

// not putting dice-candidate here since this is a v2 token
export type DiceJWTScopes =
  | 'dice-customer-recruiter'
  | 'dice-customer-admin'
  | 'dice-admin'
  | 'dice-talent-search'
  | 'dice-company-reports'
  | 'dice-aggregate-reports';

export type TokenConstants = {
  token_use: string;
  iss: string;
  client_id: string;
  role: string;
  exp: number;
  iat: number;
};

export type V2AccessToken = TokenConstants & {
  client_id: string;
  principal_id: string;
  legacy_token: string;
  legacy_refresh: string;
  inactivity_exp: number;
  user_id: number;
  company_id: number;
  scopes: DiceJWTScopes[];
  kid: string;
};

export const parseAccessToken = (
  accessTokenEncoded?: string
): Partial<V2AccessToken> | undefined => {
  const splitTokenEnc = accessTokenEncoded?.split('.')[1];

  if (!splitTokenEnc) return;
  const accessTokenPayload = base64url.decode(splitTokenEnc);
  return JSON.parse(accessTokenPayload);
};

export const getAccessTokenStatus = (
  accessToken?: Partial<V2AccessToken>,
  additionalBufferSeconds = 0
) => {
  if (!accessToken) return 'noAccessToken';

  if (!accessToken.role) {
    return 'candidateAccessToken';
  }

  const exp = typeof accessToken.exp === 'number' ? accessToken.exp : 0;
  const bufferedTtl = Date.now() / 1000 + additionalBufferSeconds;

  if (exp > bufferedTtl) {
    return 'freshAccessToken';
  }

  return 'expiredAccessToken';
};


export const CookieKeys = {
  ACCESS_TOKEN: 'access',
  IDENTITY_TOKEN: 'identity',
  REFRESH_TOKEN: 'refreshToken'
} as const;

export const LocalStorageKeys = {
  REMEMBER_ME: 'getRememberMeFromLocalStorage',
  IDENTITY_TOKEN: 'identity',
  REFRESH_TOKEN: 'refreshToken',
  IMAGE_URL: 'diceRecruiterProfileImageUrl',
};

export const getAccessToken = () => Cookies.get(CookieKeys.ACCESS_TOKEN);
export const getIdentityToken = () => Cookies.get(CookieKeys.IDENTITY_TOKEN);
export const getRefreshToken = () => Cookies.get(CookieKeys.REFRESH_TOKEN);

export const getAccessTokenCookieOptons = () => ({
  domain: getDomainWithoutSubdomain(window.location.href),
  path: '/',
  SameSite: 'lax',
  // Set access cookies to expire in 24 hours
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24)
});

export const getIdentityAndRefreshTokenCookieOptions = () => ({
  domain: getDomainWithoutSubdomain(window.location.href),
  path: '/',
  SameSite: 'lax',
  // Set identity and refresh tokens to expire in 30 days
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
  secure: true
});

/**
 * Scorched earth approach, delete all cookies within our subdomain and base domain
 * to ensure customers can always log in.
 */
export const deleteAllCookies = () => {
  const baseDomain = getDomainWithoutSubdomain(window.location.href);
  const cookies = document.cookie.split(";");
  cookies.forEach(cookie => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // clear all www cookies
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      // clear all root domain cookies
      document.cookie =
        `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${baseDomain};path=/`;
  });
}

export const setTokenCookies = (
  accessToken = '',
  identityToken = '',
  refreshToken = ''
) => {
  const accessTokenOptions = getAccessTokenCookieOptons();
  const identityAndRefreshOptions = getIdentityAndRefreshTokenCookieOptions();
  Cookies.set(CookieKeys.ACCESS_TOKEN, accessToken, accessTokenOptions);
  Cookies.set(CookieKeys.IDENTITY_TOKEN, identityToken, identityAndRefreshOptions);
  Cookies.set(CookieKeys.REFRESH_TOKEN, refreshToken, identityAndRefreshOptions);
};

export const clearTokenCookies = () => {
  const accessTokenOptions = getAccessTokenCookieOptons();
  const identityAndRefreshOptions = getIdentityAndRefreshTokenCookieOptions();
  try {
    Cookies.remove(CookieKeys.ACCESS_TOKEN, accessTokenOptions);
    Cookies.remove(CookieKeys.IDENTITY_TOKEN, identityAndRefreshOptions);
    Cookies.remove(CookieKeys.REFRESH_TOKEN, identityAndRefreshOptions);
  } catch (e) {
    console.warn(e);
  }
};

export const getRememberMeFromLocalStorage = () =>
  localStorage.getItem(LocalStorageKeys.REMEMBER_ME) ?? undefined;

export const setTokenLocalStorage = (
  identityToken = '',
  refreshToken = ''
) => {
  localStorage.setItem(LocalStorageKeys.IDENTITY_TOKEN, identityToken);
  localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, refreshToken);
}

export const clearTokenLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.IDENTITY_TOKEN);
  localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
  localStorage.removeItem(LocalStorageKeys.IMAGE_URL);
}
