<script lang="ts" setup>
// Queries & Mutations
import consola from 'consola'
import DiceModal from '../../components/core/DiceModal/DiceModal.vue';
import CallIcon from '../../components/core/icons/CallIcon.vue';
import { useTokenStore } from '../../stores/tokenStore';
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useMfaStore } from '../../stores/mfaStore'
import {
  clearTokenCookies,
  clearTokenLocalStorage,
  getAccessTokenStatus,
  setTokenCookies,
  setTokenLocalStorage
} from '../../domain/token'
import {getEnvironment, redirect} from '../../lib/url.lib'
import getApolloClient from '../../lib/apollo.client'
import {decodeJwt} from '../../lib/jwt.lib'
import {
  InitiateSignInResult,
  initiateSignInMutation,
  InitiateSignInInput,
  isAuthSuccess,
  isMfaAuthSuccess
} from '../../domain/auth-api/mutations/initiate-signin';
import { GtmEvent, GtmEventAction, GtmEventCategory, GtmEventLabel, gaDataLayerPush } from '../../domain/ga-tracking';
import SsoLayout from '../../layouts/SsoLayout.vue';
import Card from '../../components/Card.vue'
const baseDomain = process.env.BASE_DOMAIN;

const password = ref('')
const status = ref('')
const processingSignIn = ref(false)
const isVisible = ref(false)

const mfaStore = useMfaStore()
const tokenStore = useTokenStore()

onBeforeMount(() => {
  const accessToken = tokenStore.token;
  switch(getAccessTokenStatus(accessToken)){
    case 'freshAccessToken':
      redirectToLanding()
      break
    case 'expiredAccessToken':
      status.value = 'Your session has expired. Please log in again.'
        if (getEnvironment() === 'prod' || getEnvironment() === 'pilot') {
          clearTokenCookies()
        }
        clearTokenLocalStorage()
      break
    case 'noAccessToken':
    case 'candidateAccessToken':
    default:
  }
})

/** Computed */
const isUserLocked = computed(() =>
    /Your account is locked\. Please Contact Support\./i.test(status.value)
);
const isFormInputValid = computed(
    () => password.value.length > 1 && password.value.length < 73
);

/** Actions */
const redirectToLanding = () => redirect(`${baseDomain}/employer/login-landing`, mfaStore.queryParams);

const redirectToMfaMethodSelection = ({ mfaChannels}: InitiateSignInResult["initiateSignIn"]) => {
  saveUserInputs(mfaChannels)
  mfaStore.ENABLE_AUTHENTICATION()
}

const redirectToCandidateLogin = () => redirect(`${baseDomain}/dashboard/login`)

const saveUserInputs = (mfaChannels: InitiateSignInResult["initiateSignIn"]["mfaChannels"]) => {
  mfaStore.SAVE_PASSWORD(password.value.trim())
  mfaStore.SAVE_QUERY_PARAMS(mfaStore.queryParams ?? '')

  try {
    const phoneNumber = mfaChannels?.find(channel => channel.channelType === 'sms')
    if (phoneNumber) {
      mfaStore.ENABLE_MFA_PHONE([phoneNumber.channelDescription])
    }
  } catch (e) {
    consola.log(e)
  }
}

const openModal = () => {
  isVisible.value = true
}

const closeModal = () => {
  isVisible.value = false
}

const isCandidate = (token: string): boolean => {
  const decodedToken = decodeJwt(token)
  if (!decodedToken) return false
  return decodedToken?.role === 'candidate'
}

const submit = async () => {
  if (processingSignIn.value) { return }
  if (!isFormInputValid.value) {
    status.value = 'Please enter a password to sign in'
    return
  }

  try {
    processingSignIn.value = true
    const userEmail = mfaStore.email
    const initiateSignInResult = await getApolloClient().mutate<InitiateSignInResult, InitiateSignInInput>({
      mutation: initiateSignInMutation,
      variables: {
        email: userEmail,
        password: password.value.trim(),
        deviceId: mfaStore.deviceId ?? undefined,
      },
      fetchPolicy: 'no-cache'
    })
    if (!initiateSignInResult?.data?.initiateSignIn) {
      status.value = 'Unexpected sign in response';
      return;
    }

    const { data: { initiateSignIn } } = initiateSignInResult
    const { status: statusMessage, access_token, identity_token, refresh_token } = initiateSignIn

    mfaStore.MAXIMUM_CODE_VALIDATION_ATTEMPTS_MET(false)

    if (isAuthSuccess(initiateSignIn)) {

      if (isCandidate(access_token)) {
        return redirectToCandidateLogin()
      }
      // Remember Me acts the same as non-MFA login
      if (getEnvironment() === 'prod' || getEnvironment() === 'pilot') {
        setTokenCookies(access_token, identity_token, refresh_token)
      }
      setTokenLocalStorage(identity_token, refresh_token)
      gaDataLayerPush(
          GtmEvent.LoginEvent,
          GtmEventCategory.Kpi,
          GtmEventAction.Login,
          GtmEventLabel.Success
      )
      redirectToLanding()
    } else if (isMfaAuthSuccess(initiateSignIn)) {
      redirectToMfaMethodSelection(initiateSignIn)
    } else {
      status.value = statusMessage
      gaDataLayerPush(
          GtmEvent.LoginEvent,
          GtmEventCategory.Kpi,
          GtmEventAction.Login,
          GtmEventLabel.Failure
      )
    }
  } catch (ex) {
    consola.error('Error:', ex)
  } finally {
    processingSignIn.value = false
  }
}
</script>

<template>
  <SsoLayout class="flex">
    <DiceModal data-cy="contactSupportModal" :is-visible="isVisible" @cancel="closeModal" @confirm="closeModal">
      <template #title>
        Contact Support
      </template>
      <template #body>
        <div class="flex mb-10">
          <div class="flex-row">
            <div class="flex items-start m-auto">
              <CallIcon color="dice-blue-400" :width="20" />
              <div class="pl-1">
                <span id="phoneText" class="dice-text-500 dice-link">888-321-DICE</span><span
                  id="diceDigits"
                  class="dice-text-500"
              >(3423)</span>
              </div>
            </div>
            <p id="hoursOperation" class="dice-text-500">
              Monday - Friday, 8am to 5pm CST
            </p>
            <div class="flex items-center m-auto pt-4">
              <img src="../../assets/icons/mail.svg" alt="mail" class="w-20px">
              <a href="mailto:support@dice.com" role="link" class="pl-1 dice-link">support@dice.com</a>
            </div>
          </div>
        </div>
      </template>
      <template #footer />
    </DiceModal>

    <Card>
      <h1 id="signin" title="sign-in" class="dice-text-100 text-center pb-2" data-cy="signInHeader">
        Welcome Employers
      </h1>
      <h2 id="login" class="text-xl text-center font-normal break-words pb-12">
        Log in to continue
      </h2>
        <form name="login" autocomplete="on" @submit.prevent="submit">
          <label class="dice-label" for="password" data-cy="passwordLabel">
            Password <span class="text-red-500">*</span>
          </label>
          <input
              id="password"
              v-model="password"
              type="password"
              name="password"
              class="dice-input"
              autocomplete="current-password"
              data-cy="passwordInput"
          >

          <div id="errorText" class="h-9" aria-live="polite">
            <p v-if="status.length > 1 && status !== 'SUCCESS' && !isUserLocked" class="py-2 text-sm font-light text-dice-red-500" data-cy="errorText">
              {{ status }}
            </p>
            <p v-else-if="isUserLocked" class="py-2 text-sm font-light text-dice-red-500" data-cy="errorText">
              Your account is locked. Please <button class="contact-support self-end dice-link" @click.prevent="openModal" data-cy="contactSupport">Contact Support</button>.
            </p>
          </div>

          <button role="button" class="dice-btn py-3 mt-6 w-full rounded-full" data-cy="signInButton" type="submit">
            Sign In
            <span v-if="processingSignIn === true" class="spinner-border animate-spin inline-block w-4 h-4 border-1 rounded-full" role="status">
              <span class="visually-hidden">Loading...</span>
            </span>
          </button>
          <div class="text-center pb-12">
            <a
                :href="`${baseDomain}/employer/forgotten-password`"
                class="dice-link text-sm inline-block mt-7 mb-3"
                data-cy="forgotPasswordLink"
                role="link"
            >
              <p>Forgot password?</p>
            </a>
          </div>
          <hr>
          <div class="grid grid-rows-2 mt-6 md:gap-10 pt-12">
            <div>
              <p class="dice-text text-xl pb-2" data-cy="candidateLoginHeader">
                Partner with us to help you hire top tech talent.
              </p>
              <a :href="`${baseDomain}/hiring`" class="dice-link pb-3 pt-3" data-cy="candidateLoginLink">Contact Dice</a>
            </div>
            <div>
              <p class="dice-text text-xl pt-4 pb-1 md:pt-0" data-cy="hiringRedirectHeader">
                Are you searching for a job in tech?
              </p>
              <a :href="`${baseDomain}/dashboard/login`" class="dice-link hiring-link pb-3 pt-3" data-cy="hiringRedirectLink">Register Now</a>
            </div>
          </div>
        </form>
    </Card>
  </SsoLayout>
</template>
