<template>
  <DefaultLayout>
    <div class="flex flex-col justify-center items-center">
      <img width=200 src="../assets/img/404.png" alt="404 Not Found">
      <p class="dice-text-400 pt-16 pb-12">404 - The page you're looking for couldn't be found or it may have expired.</p>
  
      <button role="button" type="button" class="dice-btn px-7 py-4"  @click="redirect">Sign In</button>
    </div>
  </DefaultLayout>
</template>

<script lang="ts">
import DefaultLayout from '../layouts/default.vue';

export default {
  components: { DefaultLayout },
  methods: {
    redirect() {
      this.$router.push({
        path: '/employer/login'
      })
    }
  }
}
</script>