<script lang="ts" setup>

</script>

<template>
  <div class="flex items-center justify-center sm:my-12 pt-0">
    <div class="bg-white p-14 sm:rounded-lg shadow-md w-full h-full md:w-auto md:h-auto md:rounded-lg  max-w-[32rem]">
      <div class="flex items-center justify-center pb-6">
        <img src="../assets/img/dice-logo-icon.svg" alt="diceSmallLogo">
      </div>
      <slot />
    </div>
  </div>
</template>