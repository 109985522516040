<template>
  <SsoLayout class="flex">
    <Card>
      <div class="flex-col">
        <h1 class="dice-text-100 text-center">Account Locked</h1>
        <br>
        <p>Your account is locked. Please <a :href="`${baseDomain}/about/contact-us`" class="contact-support self-end dice-link" data-cy="contactSupport">Contact Support</a>.</p>
      </div>
    </Card>
  </SsoLayout>
</template>

<script lang="ts">
import SsoLayout from '../../layouts/SsoLayout.vue';
import Card from '../../components/Card.vue'
export default {
  name: 'AccountLocked',
  components: { SsoLayout, Card },
  setup() {
    const baseDomain = process.env.BASE_DOMAIN;
    return { baseDomain }
  }
};
</script>
