<template>
  <button type="button" :class="style" @click="handleClick()">
    {{ btnText }}
    <span
      v-if="showLoader && mfaStore.loading"
      class="spinner-border animate-spin inline-block w-4 h-4 border-1 rounded-full"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </span>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useMfaStore } from '../../../stores/mfaStore';

const mfaStore = useMfaStore()

const props = defineProps({
    btnType: {
      type: String,
      default: 'primary'
    },
    btnText: {
      type: String,
      required: true
    },
    clickHandler: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    showLoader: {
      type: Boolean,
      required: false,
      default: false
    }
  })


  const style = computed(() => {
    if (props.btnType === 'primary') {
        return 'bg-dice-blue-500 hover:bg-dice-blue-600 text-white rounded text-center px-5 py-3 w-full font-normal';
      }

      return 'text-dice-blue-500 border border-dice-blue-500 rounded px-5 py-3 text-center w-full';
  })

  // TODO: pass the handler instead of a string for the handler
  const handleClick = () => {
    switch(props.clickHandler){
      case 'SET_MFA_CHANNEL':
        // @ts-ignore
        mfaStore.SET_MFA_CHANNEL(props.formData)
        break;
      case 'RESET_MFA_CHANNEL':
        mfaStore.RESET_MFA_CHANNEL()
        break;
      case 'ENABLE_AUTHENTICATION':
        mfaStore.ENABLE_AUTHENTICATION()
        break;
    }
  }
</script>
