<template>
  <svg
    :width="width"
    :height="width"
    :aria-label="ariaLabel"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm80 224H176a16 16 0 010-32h160a16 16 0 010 32z"
      :fill="fill"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'MinusCircle',
  props: {
    fill: {
      type: String,
      default: '#952827',
      required: false
    },
    width: {
      type: Number,
      default: 20,
      required: false
    },
    ariaLabel: {
      type: String,
      default: 'Password does not meet requirement',
      required: false
    }
  }
};
</script>
