<template>
  <div class="flex-col">
    <h1 class="dice-text-100 pb-4 text-center" data-cy="pageHeader">Password Assistance</h1>
    <h2 class="dice-text-200 pb-0.5 text-center" data-cy="pageSubHeader">Check your email</h2>
    <div id="pageText" class="dice-text-500 text-center pb-12">
      <p>If you do not receive an email from no-reply@dice.com within the next few minutes, please check your spam folder.</p>
    </div>
    <hr class="max-w-3xl py-6" />
    <h3 class="dice-text-300 text-center" data-cy="emailChangedHeader">Has your email changed?</h3>
    <p id="contactUs" class="dice-text-500 text-center">
      <a :href="`${baseDomain}/about/contact-us`" role="link" class="dice-link">Contact Support</a> if your email address has changed.
    </p>
  </div>
</template>

<script lang="ts" setup>
const baseDomain = process.env.BASE_DOMAIN;
</script>
