<template>
  <div v-if="isVisible" id="RememberMePopUp" class="popup flex flex-row">
    <div class="popupcontent">
      <p class="popuptext text-center">
        {{ text }}
      </p>
      <span class="font-extralight hover:font-extrabold ex" @click="emitCancel"
        ><CloseIcon :width="20"
      /></span>
    </div>
  </div>
</template>

<script lang="ts">
import CloseIcon from '../icons/CloseIcon.vue';

export default {
  name: 'DicePopUp',
  components: { CloseIcon },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'This is text in a pop up'
    }
  },
  methods: {
    emitCancel() {
      this.$emit('closePopup');
    }
  }
};
</script>

<style>
.ex {
  position: relative;
  padding: 6px 6px 0px 6px;
  z-index: 1;
  cursor: pointer;
  margin-bottom: auto;
  height: fit-content;
}

.popup {
  position: absolute;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  bottom: 110%;
  left: -1085%;
}

.popupcontent {
  display: flex;
  background-color: #e8eef2;
  border-radius: 6px;
}

/* The actual popup */
.popup .popuptext {
  width: 290px;
  color: #000;
  padding: 8px;
  z-index: 1;
}

/* Popup arrow */
.popup .popuptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 86.5%;
  border-width: 5px;
  border-style: solid;
  border-color: #e8eef2 transparent transparent transparent;
}
</style>
