import { z } from 'zod';
import gql from 'graphql-tag';
import {MfaChannelSchema} from "./mfa-channel";

export const initiateSignInMutation = gql`
  mutation ($email: String!, $password: String!, $deviceId: String) {
    initiateSignIn(
      input: { email: $email, password: $password, deviceId: $deviceId }
    ) {
      status
      access_token
      identity_token
      refresh_token
      mfaChannels {
        channelType
        primary
        channelDescription
      }
    }
  }
`;

export const InitiateSignInInputSchema = z.object({
  email: z.string().email(),
  password: z.string().max(72),
  deviceId: z.string().uuid().optional(),
  appClientId: z.string().optional()
});

export const InitiateSignInResultSchema = z.object({
  initiateSignIn: z.object({
    status: z.enum([
      'SUCCESS',
      'Incorrect username or password',
      'Invalid user type',
      'Invalid app client',
      'Error signing in: Please contact support',
      'Invalid input',
      'Your organization uses Single Sign On (SSO) with Dice. Please sign in using your SSO sign in.', // eslint-disable-line max-len
      'Your account is locked. Please Contact Support.'
    ]),
    access_token: z.string().optional(),
    identity_token: z.string().optional(),
    refresh_token: z.string().optional(),
    mfaChannels: z.array(MfaChannelSchema).optional()
  })
});

export type InitiateSignInInput = z.infer<typeof InitiateSignInInputSchema>;
export type InitiateSignInResult = z.infer<typeof InitiateSignInResultSchema>;

/**
 * Checks if access, identity, and refresh token exist
 *
 * @returns {boolean}
 */
// eslint-disable-next-line camelcase
export const isAuthSuccess = (initiateSignIn?: InitiateSignInResult['initiateSignIn']) => {
  if (!initiateSignIn) return false;
  const { status, access_token, identity_token, refresh_token } = initiateSignIn;
  return (
    status === 'SUCCESS' &&
    // eslint-disable-next-line camelcase
    access_token !== null &&
    // eslint-disable-next-line camelcase
    identity_token !== null &&
    // eslint-disable-next-line camelcase
    refresh_token !== null
  );
};

export const isMfaAuthSuccess = (initiateSignIn?: InitiateSignInResult['initiateSignIn']) => {
  if (!initiateSignIn) return false;
  const { status, access_token, identity_token, refresh_token } = initiateSignIn;
  return (
    status === 'SUCCESS' &&
    // eslint-disable-next-line camelcase
    access_token === null &&
    // eslint-disable-next-line camelcase
    identity_token === null &&
    // eslint-disable-next-line camelcase
    refresh_token === null
  );
};

/**
 * checks if user can be configured for MFA
 *
 * @param {initiateSignInResponse} initiateSignInResponse
 * @returns {boolean} true if user can be configured for MFA
 */
// eslint-disable-next-line max-len
export const mfaCanBeConfigured = (initiateSignInResponse?: InitiateSignInResult['initiateSignIn']) =>
  // eslint-disable-next-line max-len
  initiateSignInResponse && initiateSignInResponse.status === 'SUCCESS' && initiateSignInResponse.mfaChannels && initiateSignInResponse.mfaChannels.length > 0;

export const isRemembered = (initiateSignIn?: InitiateSignInResult['initiateSignIn']) =>
  initiateSignIn && isAuthSuccess(initiateSignIn);