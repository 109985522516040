import { z } from 'zod';
import gql from 'graphql-tag';

export const requestMfaCodeMutation = gql`
  mutation ($email: String!, $password: String!) {
    requestMfaCode(input: { email: $email, password: $password }) {
      status
      session
      smsFailure
    }
  }
`;

export const RequestMfaCodeInputSchema = z.object({
  email: z.string().email(),
  password: z.string().max(72)
});

export const RequestMfaCodeResultSchema = z.object({
  requestMfaCode: z.object({
    status: z.enum([
      'SUCCESS',
      'Incorrect username or password',
      'Invalid input',
      'Your organization uses Single Sign On (SSO) with Dice. Please sign in using your SSO sign in.', // eslint-disable-line max-len
      'Error signing in: Please contact support',
      'Your account is locked. Please Contact Support.'
    ]),
    session: z.string().optional(),
    smsFailure: z.boolean().optional()
  })
})

export type RequestMfaCodeInput = z.infer<typeof RequestMfaCodeInputSchema>;
export type RequestMfaCodeResult = z.infer<typeof RequestMfaCodeResultSchema>;