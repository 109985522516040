<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 24 24"
    class="call-icon"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3286 22.4995C17.4136 22.4995 16.1283 22.1686 14.2036 21.0933C11.8632 19.7808 10.0529 18.5691 7.72505 16.2473C5.48068 14.0044 4.38849 12.5522 2.8599 9.77063C1.13302 6.63 1.4274 4.98375 1.75646 4.28016C2.14834 3.43922 2.72677 2.93625 3.47443 2.43703C3.89909 2.1588 4.34849 1.92029 4.81693 1.72453C4.86381 1.70438 4.9074 1.68516 4.94631 1.66782C5.17834 1.56328 5.5299 1.40532 5.97521 1.57407C6.2724 1.68563 6.53771 1.91391 6.95302 2.32407C7.80474 3.16407 8.96865 5.03485 9.39802 5.9536C9.68631 6.57282 9.87709 6.98157 9.87755 7.44C9.87755 7.97672 9.60756 8.39063 9.2799 8.83735C9.21849 8.92125 9.15755 9.00141 9.09849 9.07922C8.74177 9.54797 8.66349 9.68344 8.71505 9.92532C8.81959 10.4114 9.59912 11.8584 10.8802 13.1367C12.1613 14.415 13.5666 15.1453 14.0546 15.2494C14.3068 15.3033 14.4451 15.2217 14.9288 14.8523C14.9982 14.7994 15.0694 14.7445 15.144 14.6897C15.6436 14.318 16.0383 14.055 16.5624 14.055H16.5652C17.0213 14.055 17.4118 14.2528 18.0586 14.5791C18.9024 15.0047 20.8294 16.1536 21.6746 17.0063C22.0857 17.4206 22.3149 17.685 22.4269 17.9817C22.5957 18.4284 22.4368 18.7786 22.3332 19.013C22.3158 19.0519 22.2966 19.0945 22.2765 19.1419C22.0792 19.6095 21.8392 20.0579 21.5597 20.4816C21.0615 21.2269 20.5566 21.8039 19.7138 22.1963C19.281 22.401 18.8074 22.5047 18.3286 22.4995Z"
      :fill="fill"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'CallIcon',
  props: {
    fill: {
      type: String,
      default: '#195581',
      required: false
    },
    width: {
      type: Number,
      default: 50,
      required: false
    }
  }
};
</script>
